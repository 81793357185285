import React from "react";
function Sender(props) {
  return (
    <div>
      <div className="flex w-[60%] max-[1500px]:w-[100%]">
        <div className="w-[45px] h-[45px] mr-[8px]">
          <img
            className="rounded-full w-[100%]"
            src={props.ProfileimageUrl}
            alt={props.ProfileimageAlt}
          />
        </div>
        <div className="w-[370px] max-[1200px]:w-[100%]">
          <div
            className="mt-[15px] bg-[#F8F8F9] p-[15px] rounded-[20px] rounded-tl-[0px] text-[#1E243B]
                        leading-[20px] text-[13px] font-normal"
          >
            {props.message}
          </div>
          <div className="flex justify-end gap-[8px] items-center mt-[8px]">
            <span className="w-[15px] h-[15px]">
              <img
                className="w-[100%]"
                src={props.SeenimageUrl}
                alt={props.SeenimageAlt}
              />
            </span>
            <span className="text-[#A3A3A3] leading-[18px] text-[12px] font-medium">
              {props.time}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sender;
