import React from 'react';

const PostedJobCard = ({ title, description, tag, status, location, experience, postDate }) => {
    return (
        <div className="bg-[#FFF] hover:bg-[#F5FBD6] py-[25px] px-[20px] rounded-[20px] border-2 mb-[15px] border-solid border-[30px_36px_59px_0.12] max-[480px]:px-[10px] max-[480px]:py-[15px] w-full">
            <div className="flex justify-between">
                <div className="div">
                    <span className="blocktext-[#1e243b] leading-[24px] text-[16px] font-semibold">{title}</span>
                    <span className="text-[#1E243B] opacity-[56%] font-normal leading-[24px] text-[16px] line-clamp-2">{description}</span>
                </div>
            </div>
            <button className="bg-[#D9D9D9] rounded-[4px] w-[65px] h-[23px] text-[#1E243B] leading-[15px] text-[12px] font-normal my-[20px]">{tag}</button>
            <div className="flex justify-between items-end max-[1200px]:flex-col max-[1200px]:items-start w-full">
                <div className='w-full'>
                    <div className="flex mb-[10px]">
                        <div className="w-[18px] h-[18px] mr-[10px]">
                            <img className="w-full" src="images/full_time.png" alt="" />
                        </div>
                        <span className="text-[#1e243b] text-[14px] leading-[21px] font-light ">{status}</span>
                    </div>
                    <div className="flex mb-[10px]">
                        <div className="w-[18px] h-[18px] mr-[10px]">
                            <img src="images/map.png" alt="" />
                        </div>
                        <span className="text-[#1e243b] text-[14px] leading-[21px] font-light">{location}</span>
                    </div>
                    <div className='flex w-full'>
                        <div className="flex gap-[10px]">
                            <div className="w-[18px] h-[18px]">
                                <img src="images/experience.png" alt="" />
                            </div>
                            <span className="text-[#1e243b] text-[14px] leading-[21px] font-light">{experience}</span>
                        </div>
                        <div className="flex items-center ml-auto">
                            <div className="w-[18px] h-[18px] mr-[4px]">
                                <img src="images/clock.png" alt="" />
                            </div>
                            <div>
                                <span className="text-[#1e243b] text-[14px] leading-[21px] font-light max-[399px]:text-[10px]">{postDate}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PostedJobCard
