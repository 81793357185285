import React, { useState } from "react";
import Modal from "../../modal/modal";
import { Link } from "react-router-dom";

const JobDetail = ({ detail , ind }) => {
  const [deleteModal, setDeleteModal] = useState(false);
  const [closeModal, setCloseModal] = useState(false);

  const closeModalHandler = () => {
    setDeleteModal(false);
    setCloseModal(false);
  };
  console.log("message>>>>>", detail);
  return (
    <div
      key={ind}
      className="w-[48%] border-2 rounded-[20px] p-[20px] border-solid border-[30px_36px_59px_0.12] overflow-y-auto h-[83vh] custom_scroll_bar max-[1200px]:hidden"
    >
      <div className="flex gap-[10px]">
        <button
          className="bg-[#1E243B] border-2 border-solid border-[#1E243B] rounded-[50px] font-semibold text-[#fff] w-[160px] h-[40px]"
          onClick={() => setCloseModal(true)}
        >
          Close the job
        </button>
        <button
          className="bg-[#1E243B] border-2 border-solid border-[#1E243B] rounded-[50px] font-semibold text-[#fff] w-[160px] h-[40px]"
          onClick={() => setDeleteModal(true)}
        >
          Delete the job
        </button>
        <Link to="/editjob">
          <button className="bg-[#1E243B] border-2 border-solid border-[#1E243B] rounded-[50px] font-semibold text-[#fff] w-[160px] h-[40px]">
            Edit the job
          </button>
        </Link>
      </div>
      <div className="div">
        <span className="block text-[16px] text-[#1E243B] leading-[26px] font-semibold mt-[20px] mb-[10px]">
          {detail?.jobTitle}
        </span>
      </div>
      <div className="mt-[20px] mb-[20px]">
        <span className="text-[14px] text-[#1E243B] leading-[24px] font-normal">
          About Profile
        </span>
        <p className="text-[14px] text-[#1E243B] leading-[24px] font-normal list-disc opacity-[0.7]">
          {detail?.fullJobDescription}
        </p>
      </div>
      <div className="mt-[20px] mb-[20px]">
        <span className="text-[14px] text-[#1E243B] leading-[24px] font-normal block">
          Work Experience
        </span>
        <span className="text-[14px] text-[#1E243B] leading-[24px] font-normal block opacity-[0.7]">
          {detail?.qualifications.experience}
        </span>
      </div>
      <div className="mt-[20px] mb-[20px]">
        <span className="text-[14px] text-[#1E243B] leading-[24px] font-normal block">
          Location
        </span>
        <span className="text-[14px] text-[#1E243B] leading-[24px] font-normal block opacity-[0.7]">
          {detail?.address}
        </span>
      </div>
      <div className="mt-[20px] mb-[20px]">
        <span className="text-[14px] text-[#1E243B] leading-[24px] font-normal block">
          Job Type
        </span>
        <span className="text-[14px] text-[#1E243B] leading-[24px] font-normal block opacity-[0.7]">
          {detail?.jobDetails.jobType}
        </span>
      </div>
      <div className="mt-[20px] mb-[20px]">
        <span className="text-[14px] text-[#1E243B] leading-[24px] font-normal block">
          Education
        </span>
        <span className="text-[14px] text-[#1E243B] leading-[24px] font-normal block opacity-[0.7]">
          {detail?.qualifications.highestEducation}
        </span>
      </div>
      <div className="mt-[20px] mb-[20px]">
        <span className="text-[14px] text-[#1E243B] leading-[24px] font-normal block">
          Salary Expectation
        </span>
        <span className="text-[14px] text-[#1E243B] leading-[24px] font-normal block opacity-[0.7]">
          {detail?.jobDetails.salary}
        </span>
      </div>
      <div className="mt-[20px] mb-[20px]">
        <span className="text-[14px] text-[#1E243B] leading-[24px] font-normal block">
          Skills
        </span>
        <div className="mt-[15px] flex flex-wrap gap-[10px] mb-[20px]">
          {detail?.jobSkill.map((skill, index) => (
            <div className="bg-[#F5FBD6] h-[40px] text-[#1E243B] leading-[18px] font-normal px-[30px] py-[8px] flex justify-center items-center rounded-full w-[fit-content]">
              <div key={index}>{skill}</div>
            </div>
          ))}
        </div>
      </div>
      <div className="mt-[20px] mb-[20px]">
        <button className="flex items-center justify-center gap-[6px]">
          <div className="w-[16px] h-[16px]">
            <img src="images/download_resume.png" alt="" />
          </div>
          <span className="text-[14px] text-[#1E243B] leading-[24px] font-normal">
            {" "}
            Resume
          </span>
        </button>
      </div>
      {deleteModal && (
        <Modal
          message={"Are you sure you want to delete this job?"}
          button={"Delete"}
          closeModal={closeModalHandler}
        />
      )}
      {closeModal && (
        <Modal
          message={"Are you sure want to close this job?"}
          button={"Close"}
          description={
            "Job seekers won't be able to see this job and apply once closed"
          }
          closeModal={closeModalHandler}
        />
      )}
      {}
    </div>
  );
};

export default JobDetail;
