import React from "react";

const LoginRegister = () => {
  return (
    <div className="bg-[#595959] flex justify-center items-center px-[10px] mt-[20px] md:mt-[0] h-[100vh]">
      <div className="w-[100%] max-w-[416px] px-[15px] py-[60px] bg-[#fff] rounded-[10px]">
        <div className="w-[98px] m-[auto]">
          <img src="images/login-register.png" alt="#" />
        </div>
        <h2 className="text-[#1E243B] mt-[36px] text-[24px] font-semibold text-center sm:text-[32px]">
          User not found
        </h2>
        <p className="text-[#6F7780] text-center text-[16px] mt-[12px] font-normal sm:text-[20px]">
          Your member profile is not not a registered.
          <br />
          Please register your account using
          <br />
          the option below.
        </p>
        <div className="mt-[28px] sm:mt-[35px]">
          <button className="border-[#1E243B] border border-solid rounded-[10px] w-[100%] text-[18px] bg-[#1E243B] py-[8px] font-semibold text-[#fff]">
            Register
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoginRegister;
