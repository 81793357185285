import React from "react";
import JobCard from "../component/card/jobCard";
import JobDetail from "../component/card/jobDetail";

const data = [
  {
    job_role: "PHP Developer",
    companyName: "Tracy Francis",
    jobStatus: "Full Time",
    location: "Mohali, Chandigarh",
    experience: "1-3 Years",
    postDate: "an hour ago",
    isSaved: true,
  },
  {
    job_role: "Node Developer",
    companyName: "Tracy Francis",
    jobStatus: "Full Time",
    location: "Mohali, Chandigarh",
    experience: "1-3 Years",
    postDate: "an hour ago",
    isSaved: true,
  },
  {
    job_role: "React Developer",
    companyName: "Tracy Francis",
    jobStatus: "Full Time",
    location: "Mohali, Chandigarh",
    experience: "1-3 Years",
    postDate: "an hour ago",
    isSaved: true,
  },
  {
    job_role: "Wev Developer",
    companyName: "Tracy Francis",
    jobStatus: "Full Time",
    location: "Mohali, Chandigarh",
    experience: "1-3 Years",
    postDate: "an hour ago",
    isSaved: true,
  },
  {
    job_role: "Wev Designer",
    companyName: "Tracy Francis",
    jobStatus: "Full Time",
    location: "Mohali, Chandigarh",
    experience: "1-3 Years",
    postDate: "an hour ago",
    isSaved: true,
  },
];

const savedJobDetail = [
  {
    image: "images/tracy.png",
    jobRoll: "Web Developer",
    companyName: "Tracy Francis",
    aboutDescription:
      "Problem-solving information technology specialist with two years of experience using operating and security software for various technology companies",
    workExperience: "1-3 Years",
    location: "Mohali, Chandigarh",
    jobType: "Full Time",
    education: "BTech",
    salary: "1 LPA - 2LPA",
  },
  {
    image: "images/tracy.png",
    jobRoll: "Web Developer",
    companyName: "Tracy Francis",
    aboutDescription:
      "Problem-solving information technology specialist with two years of experience using operating and security software for various technology companies",
    workExperience: "1-3 Years",
    location: "Mohali, Chandigarh",
    jobType: "Full Time",
    education: "BTech",
    salary: "1 LPA - 2LPA",
  },
];

const Saved = () => {
  return (
    <div className="main_content h-[calc(100%_-_97px)] w-full z-[0] relative pt-[30px]">
      <div className="content px-[15px] main-content_inner">
            <div className="main-content_width max-w-[1200px] mx-[auto] flex flex-wrap justify-between pb-[30px]">
                <div className="w-[48%]  max-[1200px]:w-[100%]">
                    <span className="text-[16px] text-[#1E243B] leading-[26px] font-normal mb-[20px] inline-block">
                        All Jobs
                    </span>
                    <div className="overflow-y-auto h-[100vh] custom_scroll_bar pr-[10px] max-[1200px]:!h-[auto] max-[1200px]:overflow-unset pb-[38%] xl:pb-[32%]  max-[1200px]:pr-[0px]">
                        {data.map((detail, ind) => (
                          <JobCard key={ind} jobDetail={detail} />
                        ))}
                    </div>
                </div>
                {savedJobDetail.map((detail, ind) => (
                <JobDetail
                    key={ind}
                    jobRoll={detail.jobRoll}
                    image={detail.image}
                    companyName={detail.companyName}
                    description={detail.aboutDescription}
                    experience={detail.workExperience}
                    location={detail.location}
                    jobType={detail.jobType}
                    education={detail.education}
                    salary={detail.salary}
                />
                ))}
            </div>
      </div>
    </div>
  );
};

export default Saved;
