import React from "react";

const ChatDetailCardForSeeker = ({
  jobRoll,
  image,
  experience,
  name,
  description,
  location,
  jobType,
  education,
  salary,
}) => {
  const yourSkill = {
    skills: [
      "HTML",
      "JavaScript",
      "CSS",
      "React",
      "Node.js",
      "Figma",
      "Photoshop",
    ],
  };
  const yourList = {
    items: [
      {
        id: 1,
        text: "  Responded to 75% or more applications in the past 30 days, typ cally within 1 day",
      },
    //   {
    //     id: 2,
    //     text: " Provided troubleshooting and testing services that enhanced company productivity by 20% in six months",
    //   },
    //   {
    //     id: 3,
    //     text: "Seeking a software company to apply my networking and digital forensics knowledge",
    //   },
    ],
  };
  return (
    <div className=" border-l-[1px] p-[20px] border-solid border-[30px_36px_59px_0.12] overflow-y-auto h-[calc(100%_-_0px)] custom_scroll_bar max-[1200px]:hidden">
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-[10px]">
          {/* <div className="w-[55px] h-[55px] rounded-full">
            <img className="w-full" src={image} alt="#" />
          </div> */}
          <div className="div">
            <span className="block text-[16px] text-[#1E243B] leading-[26px] font-semibold">
              {jobRoll}
            </span>
            <span className="block text-[16px] text-[#1E243B] leading-[26px] font-normal">
              {name}
            </span>
          </div>
        </div>
        <button className="bg-[#1E243B] border-2 border-solid border-[#1E243B] rounded-[50px] font-semibold text-[#fff] w-[110px] h-[40px]">
          Apply Now
        </button>
      </div>
      <div className="mt-[20px] mb-[20px]">
        <strong className="text-[14px] text-[#1E243B] leading-[24px] font-normal">
        29,805.41 - 230,598.87 a month
        </strong>
        <ul className="text-[14px] text-[#1E243B] leading-[24px] font-normal pl-[20px] list-disc opacity-[0.7]">
          {yourList.items.map((item) => (
            <li key={item.id}>{item.text}</li>
          ))}
        </ul>
      </div>
      <div>
        <h3 className="text-[16px] font-[600] text-[#1E243B]">Job details</h3>
        <p className="text-[14px] text-[#818491] font-[400]">The web designer is responsible for designing and implementing the visual elements of websites, ensuring a seamless and engaging user experience.</p>
      </div>
      <div className="mt-[20px] mb-[20px]">
        <span className="text-[14px] text-[#1E243B] leading-[24px] font-normal block">
        Salary
        </span>
        <span className="text-[14px] text-[#1E243B] leading-[24px] font-normal block opacity-[0.7]">
          {experience}
        </span>
      </div>
      <div className="mt-[20px] mb-[20px]">
        <span className="text-[14px] text-[#1E243B] leading-[24px] font-normal block">
          Location
        </span>
        <span className="text-[14px] text-[#1E243B] leading-[24px] font-normal block opacity-[0.7]">
          {location}
        </span>
      </div>
      <div className="mt-[20px] mb-[20px]">
        <span className="text-[14px] text-[#1E243B] leading-[24px] font-normal block">
          Job Type
        </span>
        <span className="text-[14px] text-[#1E243B] leading-[24px] font-normal block opacity-[0.7]">
          {jobType}
        </span>
      </div>
      <div className="mt-[20px] mb-[20px]">
        <span className="text-[14px] text-[#1E243B] leading-[24px] font-normal block">
          Education
        </span>
        <span className="text-[14px] text-[#1E243B] leading-[24px] font-normal block opacity-[0.7]">
          {education}
        </span>
      </div>
      <div className="mt-[20px] mb-[20px]">
        {/* <span className="text-[14px] text-[#1E243B] leading-[24px] font-normal block">
          Salary Expectation
        </span> */}
        <span className="text-[14px] text-[#1E243B] leading-[24px] font-normal block opacity-[0.7]">
          {salary}
        </span>
      </div>
      <div className="mt-[20px] mb-[20px]">
        <span className="text-[14px] text-[#1E243B] leading-[24px] font-normal block">
        Skill Required:-
        </span>
        <p className="text-[14px] text-[#1E243B] leading-[24px] font-normal block opacity-[0.7]">- Good at PSD to HTML conversion</p>
        <p className="text-[14px] text-[#1E243B] leading-[24px] font-normal block opacity-[0.7]">- Knowledge of CSS, Photoshop, Bootstrap, FIGMA</p>
        <p className="text-[14px] text-[#1E243B] leading-[24px] font-normal block opacity-[0.7]">- Mobile Responsiveness</p>
        <p className="text-[14px] text-[#1E243B] leading-[24px] font-normal block opacity-[0.7]">- Good at debugging</p>
      </div>
      <div className="mt-[20px] mb-[20px]">
        <span className="text-[14px] text-[#1E243B] leading-[24px] font-normal block">
        Experience
        </span>
        <p className="text-[14px] text-[#1E243B] leading-[24px] font-normal block opacity-[0.7]">- PHD 1 year (preferred)</p>
        <p className="text-[14px] text-[#1E243B] leading-[24px] font-normal block opacity-[0.7]">- Web design: 1 year (Preferred)</p>
        <p className="text-[14px] text-[#1E243B] leading-[24px] font-normal block opacity-[0.7]">- WordPress: 1 year (Preferred)</p>
        <p className="text-[14px] text-[#1E243B] leading-[24px] font-normal block opacity-[0.7]">- Chandigarh, Chandigarh (Required)</p>
        </div>
      {/* <div className="mt-[20px] mb-[20px]">
        <button className="flex items-center justify-center gap-[6px]">
          <div className="w-[16px] h-[16px]">
            <img src="images/download_resume.png" alt="" />
          </div>
          <span className="text-[14px] text-[#1E243B] leading-[24px] font-normal">
            {" "}
            Resume
          </span>
        </button>
      </div> */}
    </div>
  );
};

export default ChatDetailCardForSeeker;
