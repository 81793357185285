import React, { useState, useReducer } from "react";
import { Link , useNavigate } from "react-router-dom";

import {
  onFocusOut,
  onInputChange,
  UPDATE_FORM,
  validateInput,
} from "../component/lib/form/signupForm";

import classNames from "classnames";

const initialState = {
  designation: { value: "", touched: false, hasError: true, error: "" },
  names: { value: "", touched: false, hasError: true, error: "" },
  email: { value: "", touched: false, hasError: true, error: "" },
  isFormValid: false,
};

const formReducer = (state, action) => {
  switch (action.type) {
    case UPDATE_FORM:
      const { name, value, hasError, error, touched, isFormValid } =
        action.data;
      return {
        ...state,
        [name]: { ...state[name], value, hasError, error, touched },
        isFormValid,
      };
    default:
      return state;
  }
};

const Signup = () => {
  const [formState, dispatch] = useReducer(formReducer, initialState);
  const [selectedJobType, setSelectedJobType] = useState("");
  const navigate = useNavigate();

  const confirmValidity = (formState, dispatch) => {
    let isFormValid = true;
    for (let name in formState) {
      const item = formState[name];
      const { value } = item;
      const { hasError, error } = validateInput(name, value, formState);
      if (hasError) {
        isFormValid = false;
      }
      if (name) {
        dispatch({
          type: UPDATE_FORM,
          data: {
            name,
            value,
            hasError,
            error,
            touched: true,
            isFormValid,
          },
        });
      }
    }
  };


  const signUpJobSeeker = (name, email, designation) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
  
    const raw = JSON.stringify({
      name: name,
      designation: designation,
      email: email,
      role: "JOBSEEKER",
    });
  
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };
  
    return fetch("https://api.letshireme.com/v1/jobseeker/signup", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        return result;
      })
      .catch((error) => console.error(error));
  };
  


  const onSubmit = (e) => {
    e.preventDefault();
    confirmValidity(formState, dispatch, validateInput);
    if (formState.isFormValid) {
      const { names, email, designation } = formState;
      signUpJobSeeker(names.value, email.value, designation.value)
        .then(() => {
          navigate('/availabilty');
        });
    }
  };

  return (
    <div className="flex flex-col justify-start items-center px-[10px] sm:h-[100vh] w-[100%]">
      <div className="p-[12px] w-[100%]">
        <Link to="/">
          <img
            src="images/greenDB.png"
            alt="#"
            className="w-[176px] sm:w-[223px] h-[60px] sm:h-[76px]"
          />
        </Link>
      </div>
      <div className="w-[100%] max-w-[416px] p-[8px] mt-[22px] sm:m-auto">
        <div className=" w-[60px] md:w-[75px] m-[auto]">
          <img src="images/job-seeker-one.png" alt="#" />
        </div>
        <h2 className="text-[#1E243B] text-[24px] font-semibold text-center sm:text-[32px]">
          Sign <span className="text-[#A8C523]">Up</span>
        </h2>
        <div className="mt-[10px] sm:mt-[62px]">
          <select
            value={selectedJobType}
            onChange={(e) => {
              onInputChange(
                "designation",
                setSelectedJobType(e.target.value),
                dispatch,
                formState
              );
            }}
            className={classNames(
              "form-control poppins-familybg-[#F8F8F9] text-[#877e7e] rounded-[10px] h-[45px] px-[15px] py-[10px] mt-[10px] w-[100%] focus:outline-none border-[#6F7780] border border-solid",
              formState.designation &&
                formState.designation.touched &&
                formState.designation.hasError
                ? "border-red-600"
                : "border-off-white"
            )}
            onBlur={(e) => {
              onFocusOut("designation", e.target.value, dispatch, formState);
            }}
            id="myDropdown"
            name="myDropdown"
          >
            <option value="" disabled>Designation</option>
            <option value="Engineer">Software Developer/Engineer</option>
            <option value="Web Developer">Web Developer</option>
            <option value="IT Project Manager">IT Project Manager</option>
            <option value="Data Engineer">Data Engineer</option>
            <option value="UI/UX Designer">UI/UX Designer</option>
          </select>
          {formState.designation &&
            formState.designation.touched &&
            formState.designation.hasError && (
              <div className="error-msg">{formState.designation.error}</div>
            )}
          <div>
            <input
              placeholder="Your Name"
              className={classNames(
                "form-control poppins-familybg-[#F8F8F9] rounded-[10px] h-[45px] px-[15px] py-[10px] mt-[10px] w-[100%] focus:outline-none border-[#6F7780] border border-solid",
                formState.names.touched && formState.names.hasError
                  ? "border-red-600"
                  : "border-off-white"
              )}
              type="text"
              value={formState.names.value}
              onChange={(e) => {
                onInputChange("names", e.target.value, dispatch, formState);
              }}
              onBlur={(e) => {
                onFocusOut("names", e.target.value, dispatch, formState);
              }}
            />
            {formState.names.touched && formState.names.hasError && (
              <div className="error-msg">{formState.names.error}</div>
            )}
          </div>
          <input
            type="email"
            placeholder="Your Email"
            className={classNames(
              "form-control poppins-familybg-[#F8F8F9] rounded-[10px] h-[45px] px-[15px] py-[10px] mt-[10px] w-[100%] focus:outline-none border-[#6F7780] border border-solid",
              formState.email.touched && formState.email.hasError
                ? "border-red-600"
                : "border-black"
            )}
            value={formState.email.value}
            onChange={(e) => {
              onInputChange("email", e.target.value, dispatch, formState);
            }}
            onBlur={(e) => {
              onFocusOut("email", e.target.value, dispatch, formState);
            }}
          />
          {formState.email.touched && formState.email.hasError && (
            <div className="info-text mt-3 ml-2 text-red-600">
              {formState.email.error}
            </div>
          )}
          <br />
          <button
            onClick={onSubmit}
            className="border-[#1E243B] border mt-[25px] sm:mt-[34px] border-solid rounded-[10px] w-[100%] text-[18px] bg-[#1E243B] py-[8px] font-semibold text-[#fff]"
          >
            Sign Up
          </button>
        </div>
        <p className="text-[#6F7780] sm:mt-[30px] mt-[20px] text-[16px] sm:text-[18px] font-semibold text-center">
          Already have an account? <Link to="/login"><span className="text-[#A8C523] cursor-pointer">Login</span></Link>
        </p>
      </div>
    </div>
  );
};

export default Signup;
