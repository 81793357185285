import React, { useState } from "react";
import UserList from "../component/chatComponents/UserList.js";
import Sender from "../component/chatComponents/Sender.js";
import Reciever from "../component/chatComponents/Reciever.js";
import UserDetails from "../component/chatComponents/UserDetails.js";
import ChatDetailCardForSeeker from "../component/card/ChatDetailCardForSeeker.js";

const ChatForSeeker = () => {
  const [clickButton, setClickButton] = useState(false);

  // chat messages...
  const [messages, setMessages] = useState([]);
  const [inputText, setInputText] = useState("");

  const handleInputChange = (e) => {
    setInputText(e.target.value);
  };

  const handleSendMessage = () => {
    if (inputText.trim() !== "") {
      const newMessage = {
        text: inputText,
        sender: "", // You can have a separate state for the sender's name
      };
      setMessages([...messages, newMessage]);
      setInputText("");
    }
  };

  const formatTime = (date) => {
    const options = { hour: 'numeric', minute: '2-digit', hour12: true };
    return new Date(date).toLocaleTimeString(undefined, options);
  };

  const handleClick = () => {
    setClickButton(true);
  };

  return (
    <>
      {!clickButton ? (
        <div className="content flex items-center justify-center h-[100%] flex-col px-[15px] main-content_inner pt-[176px] pb-[120px]">
          <img src="images/chat_start.png" alt="" />
          <button
            className="bg-[#1E243B] rounded-[50px] text-[#fff] text-[26px] font-normal leading-[39px] w-[447px] h-[58px] mt-[40px] small_btn_post"
            onClick={handleClick}
          >
            <span className="w-[22px] h-[22px] inline-block align-sub mr-[10px] max-[767px]:align-middle">
              <img className="w-[100%]" src="images/let_chat.png" alt="" />
            </span>
            Let's start a chat
          </button>
        </div>
      ) : (
        <div className="main_content h-[calc(100%_-_97px)] w-full z-[0] relative border-t-[1px] border-solid border-[30px_36px_59px_0.12]">
          <div className="flex bg-[#fff] h-[calc(100vh_-_100px)]">
            <div className="max-w-[360px] w-[360px] bg-[#F8F8F9] h-[100%] flex-1 relative max-[1200px]:!max-w-[100%] max-[1200px]:!w-[100%]">
              <div className="px-[15px] py-[20px] bg-[#f8f8f9] sticky top-[0px] z-[9999]">
                <div className="bg-[#fff] radius-[45px] shadow_0px_0px_2px_1px_rgba(30 ,36 , 59 , 0.09) h-[50px] rounded-full px-[20px] py-[15px] relative pl-[45px] border-[1px] border-[solid] border-[rgba(168 , 197 , 35 ,1)] ">
                  <input
                    placeholder="Search"
                    className="w-[100%] placeholder:text-[#A3A3A3] placeholder:text-[18px] placeholder:leading-[27px] placeholder:font-normal focus:outline-0"
                  />
                  <span className="absolute left-[20px]">
                    <img src="images/search_chat.png" alt="" />
                  </span>
                </div>
              </div>
              <div className="px-[0px] overflow-y-auto h-[calc(100%_-_90px)] custom_scroll_bar">
                <UserList
                  name="Canvas Craft Media"
                  message="Are you looking for a job ..."
                  time="1:59 Am"
                  ProfileimageUrl="images/pro1.png"
                  ProfileimageAlt="user_image"
                  badgeNotification="1"
                />
                <UserList
                  name="WillShall"
                  message="Hi Are you there?"
                  time="1:59 Am"
                  ProfileimageUrl="images/pro2.png"
                  ProfileimageAlt="user_image"
                  badgeNotification="1"
                />
                <UserList
                  name="Perfect Web Services"
                  message="received"
                  time="10:23 Am"
                  ProfileimageUrl="images/pro3.png"
                  ProfileimageAlt="user_image"
                  badgeNotification="1"
                />
                <UserList
                  name="Ocode Technologies"
                  message="Hello"
                  time="10:22 Am"
                  ProfileimageUrl="images/pro4.png"
                  ProfileimageAlt="user_image"
                  badgeNotification="1"
                />
                <UserList
                  name="Auspicious Soft Pvt Ltd"
                  message="Are you looking for a ..."
                  time="10:18 Am"
                  ProfileimageUrl="images/pro1.png"
                  ProfileimageAlt="user_image"
                  badgeNotification="1"
                />
                <UserList
                  name="5bix IT Solutions"
                  message="Hi Are you there?"
                  time="10:15 Am"
                  ProfileimageUrl="images/pro2.png"
                  ProfileimageAlt="user_image"
                  badgeNotification="1"
                />
                <UserList
                  name="Zversal"
                  message="received"
                  time="9:45 Am"
                  ProfileimageUrl="images/pro3.png"
                  ProfileimageAlt="user_image"
                  badgeNotification="1"
                />
                <UserList
                  name="Mvctech Labs"
                  message="Hello"
                  time="10:01 Am"
                  ProfileimageUrl="images/pro4.png"
                  ProfileimageAlt="user_image"
                  badgeNotification="1"
                />
              </div>
            </div>
            <div className="max-w-[780px] w-[100%] h-[100%] relative max-[1500px]:max-w-[100%]">
              <div className="flex justify-between items-center py-[30px] px-[20px]">
                <button className="flex gap-[10px]">
                  <span className="w-[22px] h-[22px] inline-block">
                    <img
                      className="w-[100%]"
                      src="images/exchange.png"
                      alt=""
                    />
                  </span>
                  <span className="text-[14px] leading-[24px] text-[#1E243B] font-medium opacity-[56%]">
                    Exchange number
                  </span>
                </button>
                <button className="w-[40px] h-[40px] rounded-full bg-[#F8F8F9] flex items-center justify-center">
                  <span className="w-[20px] h-[20px]">
                    <img className="w-[100%]" src="images/video.png" alt="" />
                  </span>
                </button>
              </div>
              <div className="w-[100%] px-[20px] h-[calc(100%_-_200px)] pb-[20px] overflow-y-auto custom_scroll_bar">
                <div className="message-container">
                  <Sender
                    message="I Have 2 Year Experience"
                    ProfileimageUrl="images/receiver.png"
                    ProfileimageAlt="sender_img"
                    SeenimageUrl="images/tick.png"
                    SeenimageAlt="seen_img"
                    time="7:30 Am"
                  />
                  <Reciever
                        message="Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and 
                        scrambled it to make a type specimen book."
                        ProfileimageUrl="images/pro4.png"
                        ProfileimageAlt="pro4.png"
                        SeenimageUrl="images/tick.png"
                        SeenimageAlt="seen_img"
                        time= {formatTime(new Date())} // Add the current time to the message
                      />
                  {messages.map((message, index) => (
                    <div key={index} className="message">
                      {/* <Reciever
                        message="Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and 
                        scrambled it to make a type specimen book."
                        ProfileimageUrl="images/receiver.png"
                        ProfileimageAlt="receiver.png"
                        SeenimageUrl="images/tick.png"
                        SeenimageAlt="seen_img"
                        time= {formatTime(new Date())} // Add the current time to the message
                      /> */}
                      {/* <strong>{message.sender}</strong> {message.text} */}
                    </div>
                  ))}
                </div>
              </div>
              <div className="p-[20px] z-[9999] sticky bottom-0 border-t-[1px] border-[solid] bg-[#fff] border-[rgba(30, 36, 59, 0.12)]">
                <div className="h-[60px] flex items-center justify-between bg-[#f8f8f9] rounded-full w-[100%]">
                  <div className="flex items-center gap-[8px] pl-[30px] w-[100%]">
                    <div className="w-[15px] h-[15px]">
                      <img className="w-[100%]" src="images/write.png" alt="" />
                    </div>
                    <input
                      className="focus:outline-0 w-[100%] bg-[#f8f8f9] pr-[15px]"
                      placeholder="Write Something"
                      type="text"
                      value={inputText}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="flex items-center gap-[15px] w-[120px]">
                    <button>
                      <span className="w-[22px] h-[22px]">
                        <img
                          className="w-[100%]"
                          src="images/template.png"
                          alt=""
                        />
                      </span>
                    </button>
                    <button
                      onClick={handleSendMessage}
                      className="w-[60px] h-[60px] brounded-full"
                    >
                      <span className="w-[100%] inline-block">
                        <img
                          className="w-[100%]  inline-block"
                          src="images/send.jpg"
                          alt=""
                        />
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="max-w-[470px] w-[100%]  h-[100%] max-[1500px]:hidden">
              <div className=" border-l-[1px] p-[20px] border-solid border-[30px_36px_59px_0.12] overflow-y-auto h-[calc(100%_-_0px)] custom_scroll_bar max-[1200px]:hidden">
                <ChatDetailCardForSeeker
                  jobRoll={"i next Solutions"}
                //   image={"images/pro4.png"}
                  experience={"79,805.41 - 830,598.87 a month"}
                  name={"Chandigarh, Chandigarh"}
                  description={`ting industry. Lorem Ipsum has been the
                                industry's standard dummy text ever since the 1500s,
                                when an unknown printer took a galley of type and
                                scrambled it to make a type specimen book.`}
                  location={"Mohali ,Chandigarh"}
                  jobType={"Permanent - Full-time"}
                  education={"Bachelor's (Preferred)"}
                //   salary={"3LPA-5LPA"}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ChatForSeeker;
