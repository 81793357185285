import React, { useState} from "react";
import { BsBookmark, BsBookmarkFill } from "react-icons/bs";

const JobCard = ({ job, detailfun }) => {
  // const [isSaved, setIsSaved] = useState(job.isSaved ? true : false);
  const [isSaved, setIsSaved] = useState(job && job.isSaved ? job.isSaved : false);

  console.log('Job Object:>>>>>>>>>', job);

  const saveHandler = () => {
    setIsSaved(!isSaved);
  };

  const formatCreatedAt = (createdAt) => {
    const dateObject = new Date(createdAt);
    return dateObject.toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  };

  return (
    <div
      className="bg-[#F5FBD6] hover:bg-[#F5FBD6] py-[25px] px-[20px] rounded-[20px] border-2 mb-[15px] border-solid border-[30px_36px_59px_0.12] max-[480px]:px-[10px] max-[480px]:py-[15px]"
      onClick={() => detailfun(job._id)}
    >
      <div className="flex justify-between">
        <div className="div">
          <span className="blocktext-[#1e243b] leading-[24px] text-[16px] font-semibold">
            {job?.jobTitle}
          </span>
          <span className="block text-[#1E243B] opacity-[56%] font-normal leading-[24px] text-[16px]">
            {job?.companyName}
          </span>
        </div>
        <button className="flex">
          <div className="w-[22px]" onClick={saveHandler}>
            {isSaved ? (
              <BsBookmarkFill className="text-primary-black w-[22px] h-[22px]" />
            ) : (                                       
              <BsBookmark className=" w-[22px] h-[22px]" />
            )}
          </div>
        </button>
      </div>
      <div className="flex justify-between items-end max-[1200px]:flex-col max-[1200px]:items-start">
        <div className="mt-[30px]">
          <div className="flex mb-[10px]">
            <div className="w-[18px] h-[18px] mr-[10px]">
              <img className="w-full" src="images/full_time.png" alt="" />
            </div>
            <span className="text-[#1e243b] text-[14px] leading-[21px] font-light ">
              {job?.jobDetails.jobType}
            </span>
          </div>
          <div className="flex mb-[10px]">
            <div className="w-[18px] h-[18px] mr-[10px]">
              <img src="overflow-scroll/map.png" alt="" />
            </div>
            <span className="text-[#1e243b] text-[14px] leading-[21px] font-light">
              {job?.address}
            </span>
          </div>
          <div className="flex">
            <div className="w-[18px] h-[18px] mr-[10px]">
              <img src="images/experience.png" alt="" />
            </div>
            <span className="text-[#1e243b] text-[14px] leading-[21px] font-light">
              {job?.qualifications.experience}
            </span>
          </div>
        </div>
        <div className="flex items-end w-[max-content] h-[max-content] max-[1200px]:mt-[20px] max-[1200px]:justify-between max-[1200px]:w-[100%]">
          <div className="flex items-center">
            <div className="w-[18px] h-[18px] mr-[4px]">
              <img src="images/clock.png" alt="" />
            </div>
            <span className="text-[#1e243b] text-[14px] leading-[21px] font-light max-[399px]:text-[10px]">
           {formatCreatedAt(job?.createdAt)}
            </span>
          </div>
          <div className=" items-center hidden max-[1200px]:flex">
            <div className="w-[18px] h-[18px] mr-[4px]">
              <img src="images/view_more.png" alt="" />
            </div>
            <span className="text-[#1e243b] text-[14px] leading-[21px] font-light  max-[399px]:text-[10px]">
              View more
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobCard;
