export const UPDATE_FORM = "UPDATE_FORM";

export const validateInput = (name, value, formState) => {
  let hasError = false,
    error = "";
  switch (name) {
    case "role":
      if (value === "") {
        hasError = true;
        error = "Role type can't be empty";
      } else {
        hasError = false;
        error = "";
      }
      break;
    case "jobtype":
      if (value === "") {
        hasError = true;
        error = "Job type can't be empty";
      } else {
        hasError = false;
        error = "";
      }
      break;
    case "salary":
      if (value === "") {
        hasError = true;
        error = "Expected salary can't be empty";
      } else {
        hasError = false;
        error = "";
      }
      break;
    case "city":
      if (value === "") {
        hasError = true;
        error = "City can't be empty";
      } else {
        hasError = false;
        error = "";
      }
      break;
    case "industry":
      if (value === "") {
        hasError = true;
        error = "Industry can't be empty";
      } else {
        hasError = false;
        error = "";
      }
      break;
  }
  return { hasError, error };
};

export const onInputChange = (name, value, dispatch, formState) => {
  const { hasError, error } = validateInput(name, value, formState);
  let isFormValid = true;

  for (const key in formState) {
    const item = formState[key];
    // Check if the current field has error
    if (key === name && hasError) {
      isFormValid = false;
      break;
    } else if (key !== name && item.hasError) {
      // Check if any other field has error
      isFormValid = false;
      break;
    }
  }
  dispatch({
    type: UPDATE_FORM,
    data: { name, value, hasError, error, touched: false, isFormValid },
  });
};

export const onFocusOut = (name, value, dispatch, formState) => {
  const { hasError, error } = validateInput(name, value, formState);
  let isFormValid = true;
  for (const key in formState) {
    const item = formState[key];
    if (key === name && hasError) {
      isFormValid = false;
      break;
    } else if (key !== name && item.hasError) {
      isFormValid = false;
      break;
    }
  }
  dispatch({
    type: UPDATE_FORM,
    data: { name, value, hasError, error, touched: true, isFormValid },
  });
};
