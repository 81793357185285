import React from 'react';
function UserDetails(props) {
  return (
    <div>
        <div className="mt-[20px] mb-[20px]">
            <span className="text-[14px] text-[#1E243B] leading-[24px] font-normal">{props.title}</span>
            <p className="text-[14px] text-[#1E243B] leading-[24px] font-normal list-disc opacity-[0.7]">
            {props.description}</p>
        </div>
    </div>
  );
}

export default UserDetails;