import React, { useState, useEffect } from "react";
import JobCard from "../component/card/jobCard";
import JobDetail from "../component/card/jobDetail";
import classNames from "classnames";

const Home = () => {
  const [isShowMore, setIsShowMore] = useState(false);
  const [data, setData] = useState([]);
  const toggleReadMoreLess = () => {
    setIsShowMore(!isShowMore);
  };

  const [showDetail, setShowDetail] = useState(null);

  const showDetailHandler = (jobID) => {
    fetchData(jobID);
  };

  function fetchData(jobID) {
    let myHeaders = new Headers();
    myHeaders.append(
      "x-access-token",
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NDk5OGM5MTg0MzBiNzc5OGFmNmUxMWIiLCJpYXQiOjE2ODc5MzAzNjh9.YrI4Xj0HMvMVkqzEsYcZltg2HmYqdoQGgpNSIfwGp9I"
    );

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `https://api-letshireme.onrender.com/api/v1/job/${jobID}`,
      requestOptions
    )
      .then((response) => response.json())
      .then(
        (result) => {
          console.log("data>>>>>>>>", result.data);
          setShowDetail(result.data)
        }
      )
      .catch((error) => console.log("error", error));
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        let myHeaders = new Headers();
        myHeaders.append(
          "x-access-token",
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NDkyZGZiN2Y2YTJhNjYwY2NhMmUzYWQiLCJpYXQiOjE2ODczNTIyNDl9.qWDQ2vIgfXMw88aEixVxwPK7-eR3zCGZ-hSFUBQ4CHc"
        );

        let requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };

        const response = await fetch(
          "https://api-letshireme.onrender.com/api/v1/jobs",
          requestOptions
        );

        if (!response.ok) {
          console.error(
            "API request failed:",
            response.status,
            response.statusText
          );
          throw new Error("Failed to fetch data");
        }

        const result = await response.json();
        console.log("API response:", result);
        setData(result.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
console.log("data>>>>>>", data)
  return (
    <div className="main_content h-[calc(100%_-_97px)] w-full z-[0] relative pt-[0] md:pt-[30px] ">
      <div className="content px-[15px] main-content_inner">
        <div className="main-content_width max-w-[1200px] mx-[auto] flex flex-wrap justify-between pb-[30px]">
          <div
            className={classNames(
              "max-[1200px]:w-[100%]",
              showDetail !== null ? "w-[48%]" : "w-[96%]"
            )}
          >
            <span className="text-[16px] text-[#1E243B] leading-[26px] font-normal mb-[20px] inline-block">
              All Jobs
            </span>
            <div className="overflow-y-auto  custom_scroll_bar pr-[10px] h-[calc(100vh_-_140px)] 2xl:h-[calc(100vh_-_148px)] max-[1200px]:overflow-unset pb-[6%] 2xl:pb-[4%] max-[1200px]:pr-[0px]">
              {data.map((detail, ind) => (
                <JobCard key={ind} job={detail} detailfun={showDetailHandler} />
              ))}
            </div>
          </div>
          {showDetail !== undefined && <JobDetail detail={showDetail} />}
        </div>
        <button
          className="text-[#1e243b] text-[14px] leading-[21px] font-light  max-[399px]:text-[10px]"
          onClick={toggleReadMoreLess}
        >
          {isShowMore ? "Read Less" : "Read More"}
        </button>
      </div>
    </div>
  );
};

export default Home;
