import React, { useReducer, useState, useEffect } from "react";
import classNames from "classnames";
import { toast } from "react-toastify";
import {
  onFocusOut,
  onInputChange,
  UPDATE_FORM,
  validateInput,
} from "../component/lib/form/loginform";
import { APIBASEURL } from "../utils/paths";
import useUserAuth from "../hooks/useUserAuth";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

const registerInitialState = {
  number: { value: "", touched: false, hasError: true, error: "" },
  isFormValid: false,
};

const formsReducer = (state, action) => {
  switch (action.type) {
    case UPDATE_FORM:
      const { name, value, hasError, error, touched, isFormValid } =
        action.data;
      return {
        ...state,
        [name]: { ...state[name], value, hasError, error, touched },
        isFormValid,
      };
    default:
      return state;
  }
};

const Login = () => {
  const [viewOtpForm, setViewOtpForm] = useState(false);
  const [userPhone, setUserPhone] = useState(false);

  const [loading, setLoading] = useState(false);

  const [formSubmission, setFormSubmission] = useState({
    status: null,
    message: null,
  });

  const [formState, dispatch] = useReducer(formsReducer, registerInitialState);

  const { isLogin, setUserData } = useUserAuth();
  const navigate = useNavigate();

  // verify Option....

  const [optNumber, setOptNumber] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    if (successMessage) {
      const timer = setTimeout(() => {
        setSuccessMessage("");
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [successMessage]);

  useEffect(() => {
    console.log("islogin >>>>", isLogin);
    if (isLogin) {
      navigate("/home");
    }
  }, []);

  const confirmValidity = (formState, dispatch) => {
    let isFormValid = true;
    for (let name in formState) {
      const item = formState[name];
      const { value } = item;
      const { hasError, error } = validateInput(name, value, formState);
      if (hasError) {
        isFormValid = false;
      }
      if (name) {
        dispatch({
          type: UPDATE_FORM,
          data: {
            name,
            value,
            hasError,
            error,
            touched: true,
            isFormValid,
          },
        });
      }
    }
  };
  // console.log(formState);

  const loginHandler = () => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let raw = JSON.stringify({
      phoneNumber: formState.number.value,
    });
    setUserPhone(formState.number.value);
    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(`${APIBASEURL}/login/phone`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === 200) {
          setViewOtpForm(true);
          setLoading(false);
          console.log("result>>>", result.message);
          setFormSubmission({
            status: result.status,
            message: result.message,
          });
        } else {
          setError(result.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log("error", error);
        setFormSubmission({
          status: 400,
          message: error.message,
        });
      });
  };
  console.log("setError>>", error);
  const onSubmit = (e) => {
    e.preventDefault();
    confirmValidity(formState, dispatch, validateInput);
    if (formState.isFormValid) {
      console.log("submit");
      setLoading(true);
      loginHandler();
      console.log(formState);
    } else {
      console.log("Form is invalid", formState);
    }
  };

  const handleInputChange = (e, index) => {
    const input = e.target.value;
  
    // Validate for numeric input and a length of 4 characters
    if (/^\d{0,4}$/.test(input) || input === "") {
      let newOptNumber = [...optNumber]; // Copy the array to avoid mutating state directly
      newOptNumber[index - 1] = input; // Update the corresponding index
      setOptNumber(newOptNumber);
      setError("");
  
      // Check for Backspace key press to move back to the previous input field
      if (e.nativeEvent.inputType === "deleteContentBackward" && index > 0 && input === "") {
        const prevIndex = index - 1;
        const prevInput = document.getElementById(`number${prevIndex}`);
        if (prevInput) {
          prevInput.focus();
        }
      } else if (input !== "" && input.length === 1) {
        // Focus on the next input field if it exists
        const nextIndex = index + 1;
        const nextInput = document.getElementById(`number${nextIndex}`);
        if (nextIndex <= 4 && nextInput) {
          nextInput.focus();
        }
      }
    } else {
      setError("Please enter a valid 4-digit number.");
    }
  };

  const handleResendOTP = () => {
    setError("");
    setSuccessMessage("");
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      phoneNumber: userPhone,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("https://api.letshireme.com/v1/resendOtp", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === 200) {
          setSuccessMessage("OTP resent successfully!");
        } else {
          setError("Incorrect Otp.");
        }
      })
      .catch((error) => {
        setError("Incorrect Otp.");
        console.error(error);
      });
  };

  const startResendTimer = () => {
    const timer = setTimeout(() => {
      setError("OTP verification failed. Resending OTP...");
      setTimeout(() => {
        handleResendOTP();
      }, 2000);
    }, 10000);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (optNumber.length === 4) {
      const otpString = optNumber.join("");
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        phoneNumber: userPhone,
        otp: otpString,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(`${APIBASEURL}/verifyOtp`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.status === 200) {
            // setSuccessMessage("Verification successful!");
            setUserData(result.data);
            toast.success("Welcome to Letshireme 🙂", {
              position: toast.POSITION.BOTTOM_CENTER,
            });
            navigate("/home");
          } else {
            // setError("Failed to verify OTP. Please try again.");
            toast.error("Failed to verify OTP. Please try again.", {
              position: toast.POSITION.BOTTOM_CENTER,
            });
            startResendTimer();
          }
        })
        .catch((error) => {
          setError("Incorrect Otp.");
          console.log("error", error);
        });
      // Perform your action here with the validated optNumber
      console.log("Verify OPT Number:", optNumber);
    } else {
      setError("Please enter a valid 4-digit number.");
    }
    // setOptNumber("");
  };
  console.log("formSubmission", formSubmission);
  return (
    <>
      <div className="flex items-center flex-col justify-start w-[100%] sm:h-[100vh]">
        <div className="p-[12px] w-[100%]">
          <Link to="/">
            <img
              src="images/greenDB.png"
              alt="#"
              className="w-[176px] sm:w-[223px] h-[60px] sm:h-[76px]"
            />
          </Link>
        </div>
        {!viewOtpForm ? (
          <div className="relative flex justify-center w-[100%] max-[991px]:px-[15px] 2xl:py-[60px]">
            <div className="max-w-[570px] w-[100%]">
              <div className="rounded-[48px] box-shadow: 0px_4px_48px_-6px rgba(0, 0, 0, 0.09) max-w-[480px] p-[35px] mx-auto max-[480px]:px-[15px] max-[767px]:rounded-[20px] pb-[60px]">
                <img
                  className="mx-auto my-[20px] w-[80px] sm:w-[128px] h-[80px] sm:h-[128px]"
                  src="/images/mobile-app (1) 1.png"
                  alt="signup"
                  width={50}
                  height={100}
                  quality={100}
                />
                <div className="text-center block text-black text-[20px] sm:text-[32px] leading-[45px] font-semibold mb-[15px]">
                  Login with{" "}
                  <span className="text-[#A8C523]">Mobile Number</span>
                </div>
                <span className="block text-[14px] sm:text-[18px] sm:w-[372px] text-center font-medium text-[#6F7780] mt-[-18px] sm:my-[15px]">
                  Please enter your registered mobile number. We will send you
                  an OTP to verify.
                </span>
                <form
                  onSubmit={onSubmit}
                  className="flex flex-col gap-[16px] sm:gap-[30px] max-[991px]:flex-col w-[100%] mx-auto max-[480px]:!w-[100%] mt-[18px] sm:mt-[40px]"
                >
                  <div className="w-[100%]">
                    <span>
                      <input
                        className={classNames(
                          " flex max-[991px]:w-[100%] text-[black] px-[22px] rounded-[10px] max-[767px]:max-w-[100%] max-[767px]:rounded-[10px] max-[767px]:bg-[20px_center] max-[767px]:px-[15px] h-[50px] bg-transparent border border-solid border-[#6F7780] max-[767px]:max-w-[100%  h-[100%] placeholder:text-[#6F7780] placeholder:bg-transparent placeholder:text-[18px] placeholder:font-normal placeholder:leading-[28px] relative focus:outline-[0] w-full max-w-[408px] text-[#6F7780] focus:bg-transparent",
                          formState.number.touched && formState.number.hasError
                            ? "border-red-600"
                            : "border-off-white"
                        )}
                        autoComplete="off"
                        id="number"
                        type="text"
                        placeholder="Phone Number"
                        value={formState.number.value}
                        maxLength={10}
                        onChange={(e) => {
                          if (!isNaN(+e.target.value)) {
                            onInputChange(
                              "number",
                              e.target.value,
                              dispatch,
                              formState
                            );
                          }
                        }}
                        onBlur={(e) => {
                          onFocusOut(
                            "number",
                            e.target.value,
                            dispatch,
                            formState
                          );
                        }}
                      />
                    </span>
                    {formState.number.touched && formState.number.hasError && (
                      <div className="info-text mt-3 ml-2 text-red-600">
                        {formState.number.error}
                      </div>
                    )}
                    {error && (
                      <div className="text-red-600 mt-3 ml-2">{error}</div>
                    )}
                  </div>
                  <button
                    type="submit"
                    className="flex items-center gap-2 row w-[100%] h-[50px] px-[15px] bg-[#1E243B] rounded-[10px] justify-center max-[480px]:w-[100%] max-[480px]:ml-[0]"
                  >
                    <span className="text-[#fff] font-semibold text-[18px] leading-[22px]">
                      {loading ? "Login..." : "Continue"}
                    </span>
                  </button>
                  {formSubmission.status !== null &&
                    formSubmission.message !== null && (
                      <p
                        className={
                          formSubmission.status === 400
                            ? "text-green-600"
                            : "text-red-600"
                        }
                      >
                        {formSubmission.message}
                      </p>
                    )}
                </form>
                <div className="text-[#6F7780] mt-[12px] ml-[12px] text-center text-[16px] sm:text-[18px] font-medium">
                  Don't have an account ?{" "}
                  <Link to="/recruiteJobSeeker">
                    <span className="text-[#A8C523] font-semibold">
                      Sign up
                    </span>
                  </Link>
                </div>
                <div className="text-[#6F7780] mt-[12px] ml-[12px] text-center text-[16px] sm:text-[18px] font-medium ">
                  <Link to="/emailLogin">
                    <span className="text-[#A8C523] font-semibold">
                      Login with Email
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="relative flex justify-center w-[100%] max-[991px]:px-[15px] 2xl:py-[60px]">
            <div className="max-w-[570px] w-[100%]">
              <div
                className="rounded-[48px] box-shadow: 0px_4px_48px_-6px rgba(0, 0, 0, 0.09) max-w-[480px] p-[35px] mx-auto max-[480px]:px-[15px] max-[767px]:rounded-[20px]
                pb-[60px]"
              >
                <img
                  className="mx-auto my-[20px] w-[80px] sm:w-[128px] h-[80px] sm:h-[128px]"
                  src="/images/safe-mail (1) 1.png"
                  alt="signup"
                  width={50}
                  height={100}
                  quality={100}
                />
                <div className="text-center block text-black text-[28px] sm:text-[32px] leading-[45px] font-semibold mb-[15px]">
                  Enter <span className="text-[#A8C523]">OTP code</span>
                </div>
                <span className="block text-[16px] sm:text-[18px] sm:w-[372px] text-center font-medium text-[#6F7780] my-[15px]">
                  Please enter the OTP code sent to your mobile number +91
                  9999988888
                </span>
                <form
                  onSubmit={handleSubmit}
                  className="flex flex-col gap-[20px] sm:gap-[30px] max-[991px]:flex-col w-[100%] mx-auto max-[480px]:!w-[100%] mt-[18px] sm:mt-[40px]"
                >
                  <div className="flex space-x-4">
                    {[1, 2, 3, 4].map((index) => (
                      <span
                        key={index}
                        className="relative flex max-w-[991px]:w-[100%] max-w-[767px]:max-w-[100%] max-w-[767px]:rounded-full max-w-[767px]:bg-[20px_center] max-w-[767px]:px-[15px] h-[50px] bg-[#ffffff05] rounded-[10px] border border-solid border-[#6F7780]"
                      >
                        <input
                          className="bg-transparent h-full placeholder:text-black placeholder:bg-transparent placeholder:text-[18px] pl-[24px] placeholder:font-normal placeholder:leading-[28px] relative focus:outline-none w-full max-w-[230px] text-black focus:text-[#6F7780] focus:bg-transparent"
                          autoComplete="off"
                          id={`number${index}`}
                          type="text"
                          placeholder=""
                          maxLength={1}
                          value={optNumber[index - 1] || ""}
                          onChange={(e) => handleInputChange(e, index)}
                        />
                      </span>
                    ))}
                  </div>
                  <button
                    type="submit"
                    className="gap-2 row w-[100%] h-[50px] px-[15px] bg-[#1E243B] rounded-[10px] flex justify-center items-center max-[480px]:w-[100%] max-[480px]:ml-[0]"
                  >
                    <span className="text-[#fff] font-semibold text-[18px] leading-[22px]">
                      Continue
                    </span>
                  </button>
                  <div
                    onClick={handleResendOTP}
                    className="text-[#6F7780] ml-[12px] text-center text-[16px] sm:text-[18px] font-medium cursor-pointer"
                  >
                    Did you not receive OTP ?{" "}
                    <span className="text-[#A8C523] font-semibold">
                      Resend OTP
                    </span>
                  </div>
                </form>
                {error && (
                  <div className="text-red-500 text-sm mt-2 text-center">
                    {error}
                  </div>
                )}
                {successMessage && (
                  <div className="text-[#faebd7] text-center text-[20px] mt-[12px]">
                    {successMessage}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Login;
