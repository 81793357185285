import React, { useState } from "react";
import { Link } from "react-router-dom";
import Accordion from "../component/accordion/accordion";

const ProfileHR = () => {
  const [emailContent, setEmailContent] = useState("");
  const [selectedOption, setSelectedOption] = useState('');
  

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    console.log("setSelectedOption>>>>>>>>>>>" , event)
  };

  const sendEmail = () => {
    const subject = encodeURIComponent("Email Subject");
    const body = encodeURIComponent(emailContent);

    // Use 'mailto' with a JavaScript function to open the user's default email client
    // Note: This method may not work in all browsers, and it relies on the user's settings
    window.location.href = `mailto:recipient@example.com?subject=${subject}&body=${body}`;
  };



  const handleDownload = () => {
    // Replace this with the actual content of your resume
    const resumeContent = 'Your resume content here';
    
    // Create a Blob with the content
    const blob = new Blob([resumeContent], { type: 'text/plain' });

    // Create a URL for the Blob
    const url = URL.createObjectURL(blob);

    // Create a link element and trigger a click with the download attribute
    const a = document.createElement('a');
    a.href = url;
    a.download = 'resume.txt'; // Change the filename as needed
    a.click();

    // Revoke the Object URL to free up resources
    URL.revokeObjectURL(url);
  };

  const accordionItems = [
    {
      title: 'Education',
      content: 'Content for section 1',
    }
  ];

  return (
    <>
      <div className="overflow-y-auto h-[calc(100vh_-_0px)] overflow-auto overflow-scroll">
        <div className="wrap lg:flex gap-[20px] py-[50px] px-[20px] lg:px-[50px] xl:px-[150px] ">
          <div className="w-[100%] lg:w-[35%] h-[100%] bg-[#F7F7F7] p-[20px]">
            <div className="flex flex-col justify-center items-center">
              <img
                src="images/seeker.png"
                alt="save"
                className="mr-[10px]"
                width={154}
                height={154}
              />
              <strong className="text-[#1E243B] text-[18px] font-medium mt-[10px]">
                Ananya Grover
              </strong>
              <span className="text-[14px] text-[#828282] font-medium inline-block">
                UI/UX Designer
              </span>
              <p className="text-[14px] text-[#828282] font-medium text-center !mt-[15px]">
                Full stack product designer with hands-on experience in solving
                problems for clients ranging from Real Estate, Hospitality,
                Rentals, On Demand Healthcare, IT Services & Social Network
                among others. I've good communication skills, well-defined
                process for engagement, a toolkit for collaboration & a
                user-centered approach to design.
              </p>
            </div>
            <div className="mt-[40px]">
              <strong className="text-[#1E243B] text-[18px] font-medium">
                Skills
              </strong>
              <div className="flex flex-wrap gap-[8px] mt-[15px]">
                <span className="text-[#fff] text-[12px] font-normal bg-[#1E243B] px-[15px] py-[8px]">
                  User Interface Designing
                </span>
                <span className="text-[#fff] text-[12px] font-normal bg-[#1E243B] px-[15px] py-[8px]">
                  UX
                </span>
                <span className="text-[#fff] text-[12px] font-normal bg-[#1E243B] px-[15px] py-[8px]">
                  UI
                </span>
                <span className="text-[#fff] text-[12px] font-normal bg-[#1E243B] px-[15px] py-[8px]">
                  Adobe XD
                </span>
                <span className="text-[#fff] text-[12px] font-normal bg-[#1E243B] px-[15px] py-[8px]">
                  Mobile Apps
                </span>
                <span className="text-[#fff] text-[12px] font-normal bg-[#1E243B] px-[15px] py-[8px]">
                  User Reaserch
                </span>
                <span className="text-[#fff] text-[12px] font-normal bg-[#1E243B] px-[15px] py-[8px]">
                  Wireframing
                </span>
                <span className="text-[#fff] text-[12px] font-normal bg-[#1E243B] px-[15px] py-[8px]">
                  Information Architecture
                </span>
              </div>
            </div>
            <div className="mt-[40px] flex flex-col">
              <strong className="text-[#1E243B] text-[18px] font-medium">
                Add Notes
              </strong>
              <textarea className="text-[14px] !text-left p-[15px] text-[#828282] h-[103px] font-medium text-center !mt-[15px]">
                Add notes for future reference
              </textarea>
              <button
                className="bg-[#1E243B] text-[14px] font-bold text-[#fff] w-[169px] h-[50px] shrink-0 mt-[30px] mb-[20px]"
                type="button"
              >
                Add Note
              </button>
            </div>
          </div>
          <div className="w-[100%] lg:w-[75%]">
            <div className="bg-[#F7F7F7] p-[20px] xl:p-[40px]">
              <strong className="text-[#1E243B] text-[18px] font-medium">
                Basic Information
              </strong>
              <div className="flex mt-[40px] gap-[20px] sm:px-[10px] xl:px-[30px]">
                <div className="w-1/3 flex flex-col">
                  <span className="text-[12px] text-[#828282] font-[400] leading-[-0.078px]">
                    AGE
                  </span>
                  <strong className="text-[#1E243B] text-[11px] sm:text-[14px] font-[600] leading-[-0.078px]">
                    28 years
                  </strong>
                </div>
                <div className="w-1/3 flex flex-col">
                  <span className="text-[12px] text-[#828282] font-[400] leading-[-0.078px]">
                    YEARS OF EXPERIENCE
                  </span>
                  <strong className="text-[#1E243B] text-[11px] sm:text-[14px] font-[600] leading-[-0.078px]">
                    6 years
                  </strong>
                </div>
                <div className="w-1/3 flex flex-col">
                  <span className="text-[12px] text-[#828282] font-[400] leading-[-0.078px]">
                    PHONE
                  </span>
                  <strong className="text-[#1E243B] text-[11px] sm:text-[14px] font-[600] leading-[-0.078px]">
                    +91 98123 55679
                  </strong>
                </div>
              </div>
              <div className="flex gap-[20px] mt-[30px] smpx-[10px] xl:px-[30px]">
                <div className="w-1/3 flex flex-col">
                  <span className="text-[12px] text-[#828282] font-[400] leading-[-0.078px]">
                    СТС
                  </span>
                  <strong className="text-[#1E243B] text-[11px] sm:text-[14px] font-[600] leading-[-0.078px]">
                    12.5 Lac
                  </strong>
                </div>
                <div className="w-1/3 flex flex-col">
                  <span className="text-[12px] text-[#828282] font-[400] leading-[-0.078px]">
                    LOCATION
                  </span>
                  <strong className="text-[#1E243B] text-[11px] sm:text-[14px] font-[600] leading-[-0.078px]">
                    Ahmedabad, Gujarat
                  </strong>
                </div>
                <div className="w-1/3 flex flex-col">
                  <span className="text-[12px] text-[#828282] font-[400] leading-[-0.078px]">
                    EMAIL
                  </span>
                  <strong className="text-[#1E243B] text-[11px] sm:text-[14px] font-[600] leading-[-0.078px] break-all">
                    anaynasharma@gmail.com
                  </strong>
                </div>
              </div>
              <div className="px-[10px] xl:px-[30px] mt-[30px] flex gap-[15px]">
                <button
                  className="text-[11px] sm:text-[14px] text-[#fff] font-[700] leading-[-0.078px] p-[12px] sm:p-[0] w-[195px] h-[50px] bg-[#1E243B] flex justify-center items-center"
                  type="button"
                  onClick={handleDownload}
                >
                  <img
                    src="images/download1.png"
                    alt="save"
                    className="mr-[10px]"
                    width={20}
                    height={20}
                  />
                  Download Resume
                </button>

                <button
                  className="text-[11px] p-[12px] sm:p-[0] sm:text-[14px] text-[#1E243B] font-[700] leading-[-0.078px] w-[141px] h-[50px] border-[#1E243B] border-[2px] border-[solid] flex justify-center items-center"
                  type="button"
                  onClick={sendEmail}
                >
                  <img
                    src="images/mail3.png"
                    alt="save"
                    className="mr-[10px]"
                    width={20}
                    height={20}
                  />
                  Send Email
                </button>
              </div>
            </div>
            <div className="mt-[20px] bg-[#F7F7F7] p-[20px] xl:p-[25px]">
              <strong className="text-[#1E243B] text-[18px] font-medium">
                Experience
              </strong>
              <div className="flex items-center gap-[20px] mt-[40px] mx-[10px] xl:mx-[30px] border-b-[1px] border-[#CCCCCC] pb-[30px]">
                <img
                  src="images/stlogo.png"
                  alt="save"
                  className="mr-[10px]"
                  width={86}
                  height={86}
                />
                <div className="flex flex-col ">
                  <strong className="text-[#1E243B] text-[11px] sm:text-[14px] font-[600] leading-[-0.078px]">
                    Infosys
                  </strong>
                  <p className="text-[12px] text-[#828282] font-[400] leading-[-0.078px] mt-[5px]">
                    Product & UI/UX Designer <br /> Apr 2018 - Present | Pune,
                    India
                  </p>
                </div>
              </div>
              <div className="flex items-center gap-[20px] pt-[30px] mx-[10px] xl:mx-[30px]">
                <img
                  src="images/rs.png"
                  alt="img"
                  className="mr-[10px]"
                  width={86}
                  height={86}
                />
                <div className="flex flex-col ">
                  <strong className="text-[#1E243B] text-[11px] sm:text-[14px] font-[600] leading-[-0.078px]">
                    Ramotion Studio
                  </strong>
                  <p className="text-[12px] text-[#828282] font-[400] leading-[-0.078px] mt-[5px]">
                    Web Designer <br /> April 2015 - July 2016 | Bengaluru,
                    India
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-[20px]">
              {/* <div className="relative mb-[20px]">
                <select
                  value={selectedOption}
                  onChange={handleOptionChange}
                  className="text-[#1E243B] text-left text-[14px] font-[600] w-[100%] h-[50px] pr-[15px] pl-[15px] py-[10px] placeholder:text-[#1E243B] bg-[#F7F7F7]"
                >
                  <option value="" disabled>
                    Education
                  </option>
                  <option value="Option 1">Option 1</option>
                  <option value="Option 2">Option 2</option>
                  <option value="Option 3">Option 3</option>
                </select>
              </div>
              <div className="relative mb-[20px]">
                <select
                  className="text-[#1E243B] text-left text-[14px] font-[600] w-[100%] h-[50px] pr-[15px] pl-[15px] py-[10px] placeholder:text-[#1E243B] bg-[#F7F7F7]"
                >
                  <option>
                    Education
                  </option>
                  <option value="Option 1">Option 1</option>
                  <option value="Option 2">Option 2</option>
                  <option value="Option 3">Option 3</option>
                </select>
              </div> */}
            </div>
            <div className="mb-[14px]">
                <Accordion items={accordionItems} />
            </div>
            <div>
              <Link to="/home">
                <button className="bg-[#1e1c1cf2] text-[#fff] px-[22px] py-[8px] rounded-[5px]">Next</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileHR;
