import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import classNames from "classnames";
import { paths } from "../utils/paths";

const Sidebar = () => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [toggle, setToggle] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleHandler = () => {
    setToggle(!toggle);
  };

  const handleLogout = () => {
    console.log("Logout functionality triggered");
    setToggle(false);
  };

  return (
    <div
      className={`md:bg-[#1E243B] rounded-br-[30px] h-[10vh] flex md:h-[100vh] relative sidebar_design ${
        location.pathname === "/availabilty" ||
        location.pathname === "/signup" ||
        location.pathname === "/recruiteJobSeeker" ||
        location.pathname === "/dashboardLoginOTP" ||
        location.pathname === "/loginRegister" ||
        location.pathname === "/dashboardLogin" ||
        location.pathname === "/dashboardSignup" ||
        location.pathname === "/SignUpHR" ||
        location.pathname === "/availabiltyHR" ||
        location.pathname === "/login" ||
        location.pathname === "/profileHR" ||
        location.pathname === "/emailLogin" ||
        location.pathname === "/"
          ? "hidden"
          : ""
      }`}
    >
      {location.pathname !== "/profileSeeker" && (
        <div className="sidebar_wrapper">
          <div className="md:hidden" onClick={toggleMenu}>
            <img
              className="w-[50px] h-[50px] m-[auto]"
              src="images/menus-sec.png"
              alt="menu_icon"
            />
          </div>
          <div className={`sidebar ${isOpen ? "active" : ""}`}>
            <div className="mb-[50px] logo_header">
              <img src="images/nav-logo.png" alt="logo_img" />
            </div>
            <div className="md:hidden flex justify-end p-[10px]">
              <img
                className="w-[30px] h-[30px]"
                src="images/closeNav.png"
                alt="logo_img"
                style={{ filter: "invert(1)" }}
                onClick={() => setIsOpen(false)}
              />
            </div>
            <ul className="py-[30px] px-[24px]">
              <Link to={paths.dashboard} onClick={() => setIsOpen(false)}>
                <li
                  className={classNames(
                    "  font-normal text-[16px] leading-[24px] flex items-center justify-start mb-[20px] relative h-[48px]",
                    location.pathname === paths.dashboard ? "active_li" : ""
                  )}
                >
                  {location.pathname === paths.dashboard ? (
                    <>
                      <img
                        src="images/home.png"
                        alt="home"
                        className="mr-[10px]"
                        width={20}
                        height={20}
                      />
                      <span className="text-[#A8C523]">Home</span>
                    </>
                  ) : (
                    <>
                      <img
                        src="images/home-1.png"
                        alt="home"
                        className="mr-[10px]"
                        width={20}
                        height={20}
                      />
                      <span className="text-[#fff]">Home</span>
                    </>
                  )}
                </li>
              </Link>
              <Link to={paths.chat} onClick={() => setIsOpen(false)}>
                <li
                  className={classNames(
                    "  font-normal text-[16px] leading-[24px] flex items-center justify-start mb-[20px] relative h-[48px]",
                    location.pathname === paths.chat ? "active_li" : ""
                  )}
                >
                  {location.pathname === paths.chat ? (
                    <>
                      <img
                        src="images/chat.png"
                        alt="home"
                        className="mr-[10px]"
                        width={20}
                        height={20}
                      />
                      <span className="text-[#A8C523]">Chat</span>
                    </>
                  ) : (
                    <>
                      <img
                        src="images/chat-1.png"
                        alt="home"
                        className="mr-[10px]"
                        width={20}
                        height={20}
                      />
                      <span className="text-[#fff]">Chat</span>
                    </>
                  )}
                </li>
              </Link>
              <Link to={paths.jobs} onClick={() => setIsOpen(false)}>
                <li
                  className={classNames(
                    "  font-normal text-[16px] leading-[24px] flex items-center justify-start mb-[20px] relative h-[48px]",
                    location.pathname === paths.jobs ? "active_li" : ""
                  )}
                >
                  {location.pathname === paths.jobs ? (
                    <>
                      <img
                        src="images/jobs.png"
                        alt="home"
                        className="mr-[10px]"
                        width={20}
                        height={20}
                      />
                      <span className="text-[#A8C523]">Jobs</span>
                    </>
                  ) : (
                    <>
                      <img
                        src="images/jobs-1.png"
                        alt="home"
                        className="mr-[10px]"
                        width={20}
                        height={20}
                      />
                      <span className="text-[#fff]">Jobs</span>
                    </>
                  )}
                </li>
              </Link>
              <Link to={paths.savedJobs} onClick={() => setIsOpen(false)}>
                <li
                  className={classNames(
                    "  font-normal text-[16px] leading-[24px] flex items-center justify-start mb-[20px] relative h-[48px]",
                    location.pathname === paths.savedJobs ? "active_li" : ""
                  )}
                >
                  {location.pathname === paths.savedJobs ? (
                    <>
                      <img
                        src="images/saved.png"
                        alt="save"
                        className="mr-[10px]"
                        width={20}
                        height={20}
                      />
                      <span className="text-[#A8C523]">Saved</span>
                    </>
                  ) : (
                    <>
                      <img
                        src="images/saved-1.png"
                        alt="save"
                        className="mr-[10px]"
                        width={20}
                        height={20}
                      />
                      <span className="text-[#fff]">Saved</span>
                    </>
                  )}
                </li>
              </Link>
            </ul>
            {location.pathname !== "/profileSeeker" &&
              location.pathname !== "/profileSeeker" && (
                <div className="md:p-[6px] text-center lg:p-[20px] absolute bottom-[0px] send_btn w-[100%]">
                  <button className="bg-[#A8C523] rounded-[50px] md:p-[5px] lg:p-[15px] px-[24px] py-[2px] md:w-[100%]">
                    <Link to={paths.postJobs} onClick={() => setIsOpen(false)}>
                      <span className="flex justify-center items-center text-[#1E243B] font-semibold text-[12px] md:text-[14px] lg:text-[18px] leading-[27px]">
                        <img
                          className="mr-[10px] w-[20px] h-[20px] hidden md:block"
                          src="images/post.png"
                          alt=""
                        />
                        post a job
                      </span>
                    </Link>
                  </button>
                </div>
              )}
          </div>
        </div>
      )}
      {location.pathname !== "/profileSeeker" && (
        <div className="md:p-[6px] lg:p-[20px] absolute bottom-[0px] send_btn w-[50%] md:w-[100%]">
          <button className="bg-[#A8C523] rounded-[50px] md:p-[5px] lg:p-[15px] w-[100%] hidden md:block">
            <Link to={paths.postJobs}>
              <span className="flex justify-center items-center text-[#1E243B] font-semibold text-[12px] md:text-[14px] lg:text-[18px] leading-[27px]">
                <img
                  className="mr-[10px] w-[20px] h-[20px] hidden md:block"
                  src="images/post.png"
                  alt=""
                />
                post a job
              </span>
            </Link>
          </button>
        </div>
      )}
      <div className="flex h-[50px] md:h-[100px] bg-[#FFFFFF] md:hidden">
        <div className="flex items-center gap-x-[6px] md:pr-[74px] lg:pr-[50px] pt-[0] relative">
          <img
            className="profile_image profile_img"
            src="images/profile.png"
            alt=""
            width={55}
            height={55}
          />
          <span className="text-[#000] text-[14px] font-semibold leading-[21px] profile_name">
            Eva Smith
          </span>
          <div className="relative">
            <img
              className="ml-[10px] left_dropdown_profile cursor-pointer"
              src="images/drop-down.png"
              alt=""
              onClick={toggleHandler}
            />
            {toggle && (
              <div className="absolute top-[32px] right-[-5px] lg:top-full z-50 left-[] md:top-[38px] md:right-[-12px] lg:right-[-30px] bg-[#f5fbd6] w-[8rem] md:w-[8rem] py-2 rounded-md shadow-md">
                <span
                  className="block font-semibold px-4 py-2 text-gray-800 hover:bg-[#1e243b] hover:text-[white] cursor-pointer"
                  onClick={handleLogout}
                >
                  Logout
                </span>
                <span className="block font-semibold px-4 py-2 text-gray-800 hover:bg-gray-200 cursor-pointer">
                  Option 2
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
