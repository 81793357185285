import React, { useState } from "react";
import PostedJobcardDetail from "../component/card/postedJobDetailCard";
import PostedJobCard from "./../component/card/postedJobCard";

const data = [
  {
    job: "Web Developer",
    description:"We have multiple openings for Php developers and PHP programmers having good knowledge We have multiple openings for Php developers and PHP programmers having good knowledge",
    tag: "Open",
    jobStatus: "Full Time",
    location: "Mohali, Chandigarh",
    experience: "1-3 Years",
    postDate: "an hour ago",
  },
  {
    job: "React Developer",
    description: "Seeking a software company to apply my networking and digital forensics knowledge...",
    tag: "Closed",
    jobStatus: "Part time",
    location: "Delhi",
    experience: "Fresher",
    postDate: "an hour ago",
  },
  {
    job: "Node Developer",
    description:"Provided troubleshooting and testing services that enhanced company productivity ",
    tag: "Closed",
    jobStatus: "Part time",
    location: "Delhi",
    experience: "Fresher",
    postDate: "an hour ago",
  },
  {
    job: "Wev Developer",
    description:"We have multiple openings for Php developers and PHP programmers having good knowledge We have multiple openings for Php developers and PHP programmers having good knowledge",
    tag: "Open",
    jobStatus: "Full Time",
    location: "Mohali, Chandigarh",
    experience: "1-3 Years",
    postDate: "an hour ago",
  },
];
const jobDetailData = [
  {
    image: "images/tracy.png",
    jobRoll: "Web Developer",
    companyName: "Tracy Francis",
    aboutDescription:"Problem-solving information technology specialist with two years of experience using operating and security software for various technology companies",
    aboutDescriptionsec:"Provided troubleshooting and testing services that enhanced company productivity by 20% in six months",
    aboutDescriptionT:"Seeking a software company to apply my networking and digital forensics knowledge",
    workExperience: "1-3 Years",
    location: "Mohali, Chandigarh",
    jobType: "Full Time",
    education: "BTech",
    salary: "1 LPA - 2LPA",
  },
  {
    image: "images/tracy.png",
    jobRoll: "Web Developer",
    companyName: "Tracy Francis",
    aboutDescription:"Problem-solving information technology specialist with two years of experience using operating and security software for various technology companies",
    workExperience: "1-3 Years",
    location: "Mohali, Chandigarh",
    jobType: "Full Time",
    education: "BTech",
    salary: "1 LPA - 2LPA",
  }
];

const PostJobForSeeker = () => {
  const [tab, setTab] = useState(1);
  function activeTab(tabNumber) {
    setTab(tabNumber);
  }
  return (
    <div className="main_content h-[calc(100vh_-_165px)] w-full z-[0] relative mt-[30px]">
      <div className="content px-[15px] main-content_inner">
        <div className="main-content_width max-w-[1200px] mx-[auto] flex flex-wrap justify-between pb-[30px]">
          <div className="w-[48%]  max-[1200px]:w-[100%]">
            {/* <span className="text-[16px] text-[#1E243B] leading-[26px] font-normal mb-[20px] inline-block">All Jobs</span> */}
            <div className="overflow-y-auto h-[100vh] custom_scroll_bar pr-[10px] max-[1200px]:!h-[auto] max-[1200px]:overflow-unset pb-[33%] max-[1200px]:pr-[0px]">
              {data.map((detail, ind) => (
                <PostedJobCard
                  title={detail.job}
                  description={detail.description}
                  tag={detail.tag}
                  status={detail.jobStatus}
                  location={detail.location}
                  experience={detail.experience}
                  postDate={detail.postDate}
                />
              ))}
            </div>
          </div>
          {jobDetailData.map((detail, ind) => (
            <PostedJobcardDetail
              jobRoll={detail.jobRoll}
              image={detail.image}
              companyName={detail.companyName}
              description={detail.aboutDescription}
              descriptionsec={detail.aboutDescriptionsec}
              descriptionT={detail.aboutDescriptionT}
              experience={detail.workExperience}
              location={detail.location}
              jobType={detail.jobType}
              education={detail.education}
              salary={detail.salary}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default PostJobForSeeker;
