export const UPDATE_FORM = "UPDATE_FORM";

export const validateInput = (name, value, formState) => {
    let hasError = false,
        error = ""
    switch (name) {
        case "role":
            if (value.trim() === "") {
                hasError = true
                error = "Please select your role"
            } else {
                hasError = false
                error = ""
            }
            break;
        case "location":
            if (value.trim() === "") {
                hasError = true
                error = "Please choose location"
            } else {
                hasError = false
                error = ""
            }
            break;
    }
    return { hasError, error }
}

export const onInputChange = (name, value, dispatch, formState) => {
    const { hasError, error } = validateInput(name, value, formState)
    let isFormValid = true

    for (const key in formState) {
        const item = formState[key]
        // Check if the current field has error
        if (key === name && hasError) {
            isFormValid = false
            break
        } else if (key !== name && item.hasError) {
            // Check if any other field has error
            isFormValid = false
            break
        }
    }
    dispatch({
        type: UPDATE_FORM,
        data: { name, value, hasError, error, touched: false, isFormValid },
    })
}

export const onFocusOut = (name, value, dispatch, formState) => {
    const { hasError, error } = validateInput(name, value, formState)
    let isFormValid = true
    for (const key in formState) {
        const item = formState[key]
        if (key === name && hasError) {
            isFormValid = false
            break
        } else if (key !== name && item.hasError) {
            isFormValid = false
            break
        }
    }
    dispatch({
        type: UPDATE_FORM,
        data: { name, value, hasError, error, touched: true, isFormValid },
    })
}