import React from "react";

function handleSave() {
  // Handle save functionality here
}

const AllJobsForSeeker = ({}) => {
  return (
    <div className="bg-[#fefefe]">
      <div className="flex-nowrap md:flex gap-[20px] xl:gap-[40px] px-[15px] max-w-[1140px] mx-[auto] pt-[50px] overflow-y-auto h-[calc(100vh_-_120px)] overflow-auto overflow-scroll">
        <div className="w-[100%] md:w-[50%]">
          <p className="text-[16px] font-[400] text-[#1E243B] mb-[10px]">
            All Jobs
          </p>
          <div className="bg-[#F5FBD6] py-[25px] px-[20px] rounded-[20px] border-2 mb-[15px] border-solid border-[30px_36px_59px_0.12] min-h-[354px] max-[480px]:px-[10px] max-[480px]:py-[15px] w-full">
            <div>
              <strong className="text-[16px] font-[600] text-[#1E243B] flex justify-between">
                <span>Web Designer</span>
                <button className="save-button" onClick={handleSave}>
                  <img src="images/save.png" alt="img" />
                </button>
              </strong>
              <p className="text-[#7d837f] text-[16px] font-[400] mt-[20px]">
                i-next Solutions sdsd
              </p>
              <p className="text-[#7d837f] text-[16px] font-[400]">
                Chandigarh, Chandigarh
              </p>

              <div className="mt-[20px] flex flex-wrap gap-[8px]">
                <label className="text-[16px] font-[400] text-[#1E243B] bg-[#EFF9C0] px-[5px]">
                  89,805.41 - 230,598.87 a month|
                </label>
                <label className="text-[16px] font-[400] text-[#1E243B] bg-[#EFF9C0] px-[5px]">
                  Full-time +1
                </label>
                <label className="text-[16px] font-[400] text-[#1E243B] bg-[#EFF9C0] px-[5px]">
                  Call employer
                </label>
                <label className="text-[16px] font-[400] text-[#1E243B] bg-[#EFF9C0] px-[5px]">
                  Day shift
                </label>
                <label className="text-[16px] font-[400] text-[#1E243B] bg-[#EFF9C0] px-[5px]">
                  1-3 Years
                </label>
              </div>
              <div className="flex gap-[10px] mt-[20px]">
                <p className="text-[#1E243B] text-[14px] font-[300]">
                  • Easily apply
                </p>
                <p className="text-[#1E243B] text-[14px] font-[300]">
                  • Responsive employer
                </p>
              </div>
              <div className="">
                <p className="text-[#1E243B] text-[14px] font-[300] mt-[5px]">
                  * Hiring for multiple roles
                </p>
              </div>
              <div className="mt-[20px]">
                <p className="text-[#1E243B] text-[14px] font-[300]">
                  • Web design: 1 year (Preferred).
                </p>
                <p className="text-[#1E243B] text-[14px] font-[300]">
                  • Good at PSD to HTML conversion
                </p>
                <p className="text-[#1E243B] text-[14px] font-[300]">
                  • Knowledge of CSS, Photoshop, Bootstrap, FIGMA|
                </p>
              </div>
              <div className="mt-[20px] flex justify-between">
                <p className="text-[#1E243B] text-[14px] font-[300]">
                  Posted A days ago • More.
                </p>
                <p className="text-[#1E243B] text-[14px] font-[300] flex gap-[10px] items-center">
                  <img src="images/time.png" alt="img" />
                  <span>an hour ago</span>
                </p>
              </div>
            </div>
          </div>
          <div className="bg-[#fff] hover:bg-[#F5FBD6] py-[25px] px-[20px] rounded-[20px] border-2 mb-[15px] border-solid border-[30px_36px_59px_0.12] min-h-[354px] max-[480px]:px-[10px] max-[480px]:py-[15px] w-full">
            <div>
              <strong className="text-[16px] font-[600] text-[#1E243B] flex justify-between items-center">
                <span>
                  Urgent Opening || Web Designers || Cogniter Technologies
                </span>
                <button className="save-button" onClick={handleSave}>
                  <img src="images/save.png" alt="img" />
                </button>
              </strong>
              <p className="text-[#7d837f] text-[16px] font-[400] mt-[20px]">
                Cogniter Technologies{" "}
              </p>
              <p className="text-[#7d837f] text-[16px] font-[400]">
                Mohali, Chandigarh
              </p>
              <div className="flex gap-[10px] mt-[20px]">
                <p className="text-[#1E243B] text-[14px] font-[300]">
                  • Easily apply
                </p>
                <p className="text-[#1E243B] text-[14px] font-[300]">
                  • Responsive employer
                </p>
              </div>
              <div className="mt-[20px]">
                <p className="text-[#1E243B] text-[14px] font-[300]">
                  • Design website mockups, Newsletters, Logos, Icons and other
                  web graphics...
                </p>
                <p className="text-[#1E243B] text-[14px] font-[300]">
                  • Working with others, such as printers,
                  programmers,developers or other technicians,…
                </p>
                <p className="text-[#1E243B] text-[14px] font-[300]">
                  • Design website mockups, Newsletters, Logos, Icons and other
                  web graphics...
                </p>
              </div>
              <div className="mt-[20px] flex justify-between">
                <p className="text-[#1E243B] text-[14px] font-[300]">
                  Posted 4 days ago More.
                </p>
                <p className="text-[#1E243B] text-[14px] font-[300] flex gap-[10px] items-center">
                  <img src="images/time.png" alt="img" />
                  <span>4 days ago</span>
                </p>
              </div>
            </div>
          </div>
          <div className="bg-[#fff] hover:bg-[#F5FBD6] py-[25px] px-[20px] rounded-[20px] border-2 mb-[15px] border-solid border-[30px_36px_59px_0.12] min-h-[354px] max-[480px]:px-[10px] max-[480px]:py-[15px] w-full">
            <div>
              <strong className="text-[16px] font-[600] text-[#1E243B] flex justify-between">
                <span>Web Developer</span>
                <button className="save-button" onClick={handleSave}>
                  <img src="images/save.png" alt="img" />
                </button>
              </strong>
              <p className="text-[#7d837f] text-[16px] font-[400] mt-[20px]">
                MiTEMS
              </p>
              <p className="text-[#7d837f] text-[16px] font-[400]">
                Chandigarh, Chandigarh
              </p>
              <div className="flex gap-[10px] mt-[20px]">
                <p className="text-[#1E243B] text-[14px] font-[300]">
                  • Easily apply
                </p>
                <p className="text-[#1E243B] text-[14px] font-[300]">
                  • Responsive employer
                </p>
              </div>
              <div className="mt-[20px]">
                <p className="text-[#1E243B] text-[14px] font-[300]">
                  • Design website mockups, Newsletters, Logos, Icons and other
                  web graphics...
                </p>
                <p className="text-[#1E243B] text-[14px] font-[300]">
                  • Working with others, such as printers,
                  programmers,developers or other technicians,…
                </p>
                <p className="text-[#1E243B] text-[14px] font-[300]">
                  • Design website mockups, Newsletters, Logos, Icons and other
                  web graphics...
                </p>
              </div>
              <div className="mt-[20px] flex justify-between">
                <p className="text-[#1E243B] text-[14px] font-[300]">
                  Posted 4 days ago More.
                </p>
                <p className="text-[#1E243B] text-[14px] font-[300] flex gap-[10px] items-center">
                  <img src="images/time.png" alt="img" />
                  <span>4 days ago</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="w-[100%] md:w-[50%]">
          <div className="bg-[#FFF] py-[25px] px-[20px] rounded-[20px] border-2 mb-[15px] border-solid border-[30px_36px_59px_0.12] max-[480px]:px-[10px] max-[480px]:py-[15px] w-full">
            <div>
              <strong className="text-[16px] font-[600] text-[#1E243B] flex justify-between items-center">
                <span>Web Designer</span>
                <button className="text-[#fff] text-[14px] font-[600] bg-[#1E243B] rounded-[50px] w-[110px] h-[40px]">
                  Apply Now
                </button>
              </strong>
              <p className="text-[#7d837f] text-[16px] font-[400] mt-[20px] flex gap-[20px] items-center">
                <span>i-next Solutions</span>
                <img src="images/rating1.png" alt="img" />{" "}
                <span className="text-[14px] font-[400] ">47 reviews</span>
              </p>
              <p className="text-[#7d837f] text-[16px] font-[400] mb-[20px]">
                Chandigarh, Chandigarh
              </p>
              <strong className="text-[14px] font-[400] text-[#1E243B]">
                29,805.41 - 230,598.87 a month
              </strong>
              <p className="text-[#7d837f] text-[14px] font-[300] mt-[5px] mb-[30px]">
                • Responded to 75% or more applications in the past 30 days, typ
                cally within 1 day
              </p>
            </div>
            <div className="border-[1px] border-[#D9D9D9] py-[25px] px-[15px]">
              <strong className="text-[16px] font-[600] text-[#1E243B]">
                Job details
              </strong>
              <p className="text-[#7d837f] text-[16px] font-[400]">
                Here's how the job details align with your job preferences.
                Manage job preferences at any time in your profile 2,
              </p>
              <div className="mt-[20px]">
                <strong className="text-[14px] font-[400] text-[#1E243B]">
                  Pay
                </strong>
                <p className="text-[#7d837f] text-[14px] font-[300] mt-[5px] mb-[10px] ml-[10px]">
                  • 79,805.41 - 830,598.87 a month
                </p>
              </div>
              <div className="mt-[20px]">
                <strong className="text-[14px] font-[400] text-[#1E243B]">
                  Job type
                </strong>
                <p className="text-[#7d837f] text-[14px] font-[300] mt-[5px] mb-[10px] ml-[10px]">
                  • Permanent - Full-time
                </p>
              </div>
              <div className="mt-[20px]">
                <strong className="text-[14px] font-[400] text-[#1E243B]">
                  Shift and schedule
                </strong>
                <p className="text-[#7d837f] text-[14px] font-[300] mt-[5px] mb-[10px] ml-[10px]">
                  • Day shift
                </p>
              </div>
              <div className="mt-[20px]">
                <strong className="text-[14px] font-[400] text-[#1E243B]">
                  Skill Required:-
                </strong>
                <p className="text-[#7d837f] text-[14px] font-[300] mt-[5px] ml-[10px]">
                  - Good at PSD to HTML conversion
                </p>
                <p className="text-[#7d837f] text-[14px] font-[300] ml-[10px]">
                  - Knowledge of CSS, Photoshop, Bootstrap, FIGMA
                </p>
                <p className="text-[#7d837f] text-[14px] font-[300] ml-[10px]">
                  - Mobile Responsiveness
                </p>
                <p className="text-[#7d837f] text-[14px] font-[300] ml-[10px]">
                  - Good at debugging
                </p>
                <p className="text-[#7d837f] text-[14px] font-[300] mt-[15px]">
                  Any preference for profile: Male/ Female both can be
                  considered. Female preferred
                </p>
              </div>
              <div className="mt-[20px]">
                <strong className="text-[14px] font-[400] text-[#1E243B]">
                  Education:
                </strong>
                <p className="text-[#7d837f] text-[14px] font-[300] mt-[5px] mb-[10px] ml-[10px]">
                  • Bachelor's (Preferred)
                </p>
              </div>
              <div className="mt-[20px]">
                <strong className="text-[14px] font-[400] text-[#1E243B]">
                  Exopriance
                </strong>
                <p className="text-[#7d837f] text-[14px] font-[300] ml-[10px] mt-[5px] ml-[10px]">
                  - PHD: 1 vear (Proferradi)
                </p>
                <p className="text-[#7d837f] text-[14px] font-[300] ml-[10px] ml-[10px]">
                  - Web design: 1 year (Preferred)
                </p>
                <p className="text-[#7d837f] text-[14px] font-[300] ml-[10px] ml-[10px]">
                  - WordPress: 1 year (Preferred)
                </p>
                <p className="text-[#7d837f] text-[14px] font-[300] ml-[10px] ml-[10px]">
                  - Chandigarh, Chandigarh (Required)
                </p>
              </div>
              <div className="mt-[20px]">
                <strong className="text-[14px] font-[400] text-[#1E243B]">
                  Ability to Relocate
                </strong>
                <p className="text-[#7d837f] text-[14px] font-[300] mt-[5px] mb-[10px] ml-[10px]">
                  • Chandigarh, Chandigarh: Relocate before starting work
                  (Required) Work Location: in person
                </p>
              </div>
              <div className="mt-[20px]">
                <strong className="text-[14px] font-[400] text-[#1E243B]">
                  Speak with the employer
                </strong>
                <p className="text-[#7d837f] text-[14px] font-[300] mt-[5px] mb-[10px]">
                  +91 6280036002
                </p>
              </div>
            </div>
            <a
              href="path_to_resume.pdf"
              download
              className="text-[14px] font-[400] text-[#1E243B] flex gap-[10px] items-center mt-[40px]"
            >
              <img src="images/upload.png" alt="img" />
              <span>Resume</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllJobsForSeeker;
