import React, { useState } from "react";
import PostedJobcardDetail from "../component/card/postedJobDetailCard";
import PostedJobCard from "./../component/card/postedJobCard";

const data = [
  {
    job: "Wev Developer",
    description:"We have multiple openings for Php developers and PHP programmers having good knowledge We have multiple openings for Php developers and PHP programmers having good knowledge",
    tag: "Open",
    jobStatus: "Full Time",
    location: "Mohali, Chandigarh",
    experience: "1-3 Years",
    postDate: "an hour ago",
  },
  {
    job: "Wev Developer",
    description: "We have multiple openings for Php developers and PHP programmers having good knowledge We have multiple openings for Php developers and PHP programmers having good knowledge",
    tag: "Open",
    jobStatus: "Full Time",
    location: "Mohali, Chandigarh",
    experience: "1-3 Years",
    postDate: "an hour ago",
  },
  {
    job: "Wev Developer",
    description:"We have multiple openings for Php developers and PHP programmers having good knowledge We have multiple openings for Php developers and PHP programmers having good knowledge",
    tag: "Open",
    jobStatus: "Full Time",
    location: "Mohali, Chandigarh",
    experience: "1-3 Years",
    postDate: "an hour ago",
  },
  {
    job: "Wev Developer",
    description:"We have multiple openings for Php developers and PHP programmers having good knowledge We have multiple openings for Php developers and PHP programmers having good knowledge",
    tag: "Open",
    jobStatus: "Full Time",
    location: "Mohali, Chandigarh",
    experience: "1-3 Years",
    postDate: "an hour ago",
  },
];
const jobDetailData = [
  {
    image: "images/tracy.png",
    jobRoll: "Web Developer",
    companyName: "Tracy Francis",
    aboutDescription:"Problem-solving information technology specialist with two years of experience using operating and security software for various technology companies",
    aboutDescriptionsec:"Provided troubleshooting and testing services that enhanced company productivity by 20% in six months",
    aboutDescriptionT:"Seeking a software company to apply my networking and digital forensics knowledge",
    workExperience: "1-3 Years",
    location: "Mohali, Chandigarh",
    jobType: "Full Time",
    education: "BTech",
    salary: "1 LPA - 2LPA",
  },
  {
    image: "images/tracy.png",
    jobRoll: "Web Developer",
    companyName: "Tracy Francis",
    aboutDescription:"Problem-solving information technology specialist with two years of experience using operating and security software for various technology companies",
    workExperience: "1-3 Years",
    location: "Mohali, Chandigarh",
    jobType: "Full Time",
    education: "BTech",
    salary: "1 LPA - 2LPA",
  }
];

 const Job = () => {
  const [tab, setTab] = useState(1);
  function activeTab(tabNumber) {
    setTab(tabNumber);
  }
  return (
    <div className="main_content h-[calc(100%_-_97px)] w-full z-[0] relative ">
      <div className="content px-[15px] main-content_inner">
        <div className="main-content_width md:max-w-[551px] lg:max-w-[1200px] mx-[auto] flex flex-wrap justify-between pb-[30px]">
          <div className="flex h-[58px] rouned-[48px] shadow-[0px_0px_45px_-19px_rgba(205_205_205_0.83)] bg-[rgba(30, 36, 59, 0.12)] justify-start mb-[30px] custom_after_element max-[767px]:flex-col max-[767px]:h-[auto] mt-[30px] w-[100%]">
            <button
              onClick={() => activeTab(1)}
              style={{
                background: tab === 1 ? "#A8C523" : "",
                color: tab === 1 ? "#fff" : "",
              }}
              className="rounded-tl-[100px] rounded-bl-[100px] bg-[#1e243b1c] hover:bg-[#A8C523] hover:text-[#fff] text-[16px] leading-[24px] font-medium w-[205px] h-[100%] text-center max-[767px]:w-[100%] max-[767px]:rounded-[3px] max-[767px]:py-[10px] max-[767px]:border-b-[#00000012] max-[767px]:border-b-[1px]"
            >
              All Jobs
            </button>
            <button
              onClick={() => activeTab(2)}
              style={{
                background: tab === 2 ? "#A8C523" : "",
                color: tab === 2 ? "#fff" : "",
              }}
              className="bg-[text-[#1E243B] text-[16px] leading-[24px] font-normal  hover:bg-[#A8C523] w-[205px] h-[100%] text-center bg-[#1e243b1c] hover:text-[#fff] relative max-[767px]:w-[100%] max-[767px]:rounded-[3px] max-[767px]:py-[10px] max-[767px]:border-b-[#00000012] max-[767px]:border-b-[1px]"
            >
              Opening
            </button>
            <button
              onClick={() => activeTab(3)}
              style={{
                background: tab === 3 ? "#A8C523" : "",
                color: tab === 3 ? "#fff" : "",
              }}
              className="bg-[text-[#1E243B] text-[16px] leading-[24px] font-normal  hover:bg-[#A8C523] w-[205px] h-[100%] text-center bg-[#1e243b1c] hover:text-[#fff] relative max-[767px]:w-[100%] max-[767px]:rounded-[3px] max-[767px]:py-[10px] max-[767px]:border-b-[#00000012] max-[767px]:border-b-[1px]"
            >
              Close
            </button>
            <button
              onClick={() => activeTab(4)}
              style={{
                background: tab === 4 ? "#A8C523" : "",
                color: tab === 4 ? "#fff" : "",
              }}
              className="bg-[text-[#1E243B] text-[16px] leading-[24px]  hover:bg-[#A8C523] font-normal w-[205px] h-[100%] text-center bg-[#1e243b1c] rounded-tr-[100px] rounded-br-[100px] hover:text-[#fff] max-[767px]:w-[100%] max-[767px]:rounded-[3px] max-[767px]:py-[10px] max-[767px]:border-b-[#00000012] max-[767px]:border-b-[1px]"
            >
              Rejected
            </button>
          </div>
          <div className="w-[48%]  max-[1200px]:w-[100%]">
            {/* <span className="text-[16px] text-[#1E243B] leading-[26px] font-normal mb-[20px] inline-block">All Jobs</span> */}
            <div className="overflow-y-auto h-[100vh] custom_scroll_bar pr-[10px] max-[1200px]:overflow-unset pb-[24%] lg:pb-[45%] xl:pb-[40%] max-[1200px]:pr-[0px]">
              {data.map((detail, ind) => (
                <PostedJobCard
                  title={detail.job}
                  description={detail.description}
                  tag={detail.tag}
                  status={detail.jobStatus}
                  location={detail.location}
                  experience={detail.experience}
                  postDate={detail.postDate}
                />
              ))}
            </div>
          </div>
          {jobDetailData.map((detail, ind) => (
            <PostedJobcardDetail
              jobRoll={detail.jobRoll}
              image={detail.image}
              companyName={detail.companyName}
              description={detail.aboutDescription}
              descriptionsec={detail.aboutDescriptionsec}
              descriptionT={detail.aboutDescriptionT}
              experience={detail.workExperience}
              location={detail.location}
              jobType={detail.jobType}
              education={detail.education}
              salary={detail.salary}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Job;
