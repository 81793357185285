import React, { useState , useEffect } from 'react';

function Accordion({ items }) {
  const [openIndex, setOpenIndex] = useState(null);
  // const [formData, setFormData] = useState(null)

  const handleItemClick = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  // useEffect(() => {
  //   const storedFromState = localStorage.getItem("formData>>>.");
  //   if (storedFromState) {
  //     setFormData(JSON.parse(storedFromState));
  //   }
  // }, []);

  return (
    <div className="accordion">
      {items.map((item, index) => (
        <div key={index} className="accordion-item">
          <div
            className={`accordion-title ${openIndex === index ? 'open' : ''}`}
            onClick={() => handleItemClick(index)}
          >
            {item.title}
          </div>
          <div className={`accordion-content ${openIndex === index ? 'open' : ''}`}>
            {item.content}
            {/* <div>{formData && formData.instituteName}</div>
            <div>{formData && formData.highestEducation}</div>
            <div>{formData && formData.batch}</div>
            <div>{formData && formData.city}</div> */}
          </div>
        </div>
      ))}
    </div>
  );
}

export default Accordion;
