import React from "react";
import { Link } from "react-router-dom";

const RecruiteJobSeeker = () => {
  return (
    <div className="flex flex-col justify-start items-center px-[10px] md:mt-[0] sm:h-[100vh] w-[100%]">
      <div className="p-[12px] w-[100%]">
        <Link to="/">
          <img
            src="images/greenDB.png"
            alt="#"
            className="w-[176px] sm:w-[223px] h-[60px] sm:h-[76px]"
          />
        </Link>
      </div>
      <div className="flex mt-[38px] gap-[24px] flex-col sm:flex-row justify-center sm:min-h-[36%] sm:m-[auto] px-[15px] w-[100%]">
        <div className="rounded-[10px] p-[20px] md:p-[40px] w-[100%] md:w-[414px] border-[#1E243B] border border-solid text-center">
          <Link to="/SignUpHR">
            <div className="w-[60px] sm:w-[109px] m-[auto]">
              <img src="images/job-seeker-card.png" alt="#" />
            </div>
            <h2 className="text-[#1E243B] mt-[10px] text-[24px] font-semibold text-center sm:text-[32px]">
              I Am <span className="text-[#A8C523]">Recruiter</span>
            </h2>
            <p className="text-[#1E243B] text-center text-[16px] font-semibold sm:text-[20px]">
              Click Here to Reach
              <br />
              Next Details
            </p>
          </Link>
        </div>
        <div className="rounded-[10px] p-[20px] md:p-[40px] w-[100%] md:w-[414px] border-[#1E243B] border border-solid">
          <Link to="/signup">
            <div className="w-[60px] sm:w-[109px] m-[auto]">
              <img className="w-[60px] sm:w-[109px] m-[auto]" src="images/job-seeker-one.png" alt="#" />
            </div>
            <h2 className="text-[#1E243B] mt-[10px] text-[24px] font-semibold text-center sm:text-[32px]">
              I Am <span className="text-[#A8C523]">Job Seeker</span>
            </h2>
            <p className="text-[#1E243B] text-center font-semibold text-[16px] sm:text-[20px]">
              Click Here to Reach
              <br />
              Next Details
            </p>
          </Link>
        </div>
      </div>
    </div>
  );
};
export default RecruiteJobSeeker;
