export const UPDATE_FORM = "UPDATE_FORM";

export const validateInput = (name, value, formState) => {
    let hasError = false,
        error = ""
    switch (name) {
        case "company":
            if (value.trim() === "") {
                hasError = true
                error = "Company name can't be empty"
            } else if (value.trim().length < 8) {
                hasError = true
                error = "Company name should be more than 8 characters"
            } else {
                hasError = false
                error = ""
            }
            break;
        case "jobtitle":
            if (value === "") {
                hasError = true
                error = "Job title can't be empty"
            } else {
                hasError = false
                error = ""
            }
            break;
        case "jobtype":
            if (value === "") {
                hasError = true
                error = "Job type can't be empty"
            } else {
                hasError = false
                error = ""
            }
            break;
        case "experience":
            if (value === "") {
                hasError = true
                error = "Experience can't be empty"
            } else {
                hasError = false
                error = ""
            }
            break;
        case "education":
            if (value === "") {
                hasError = true
                error = "Education can't be empty"
            } else {
                hasError = false
                error = ""
            }
            break;
        case "salary1":
            if (value === "") {
                hasError = true
                error = "Salary can't be empty"
            } else {
                hasError = false
                error = ""
            }
            break;
        case "salary2":
            if (value === "") {
                hasError = true
                error = "Salary can't be empty"
            } else {
                hasError = false
                error = ""
            }
            break;
        case "jobLocation":
            if (value === "") {
                hasError = true
                error = "Job location can't be empty"
            } else if (value.trim().length < 10) {
                hasError = true
                error = "Description should have more than 10 characters"
            } else {
                hasError = false
                error = ""
            }
            break;
        case "description":
            if (value.trim() === "") {
                hasError = true
                error = "Description can't be empty"
            } else if (value.trim().length < 30) {
                hasError = true
                error = "Description should have more than 30 characters"
            } else {
                hasError = false
                error = ""
            }
            break;
    }
    return { hasError, error }
}

export const onInputChange = (name, value, dispatch, formState) => {
    const { hasError, error } = validateInput(name, value, formState)
    let isFormValid = true

    for (const key in formState) {
        const item = formState[key]
        // Check if the current field has error
        if (key === name && hasError) {
            isFormValid = false
            break
        } else if (key !== name && item.hasError) {
            // Check if any other field has error
            isFormValid = false
            break
        }
    }
    dispatch({
        type: UPDATE_FORM,
        data: { name, value, hasError, error, touched: false, isFormValid },
    })
}

export const onFocusOut = (name, value, dispatch, formState) => {
    const { hasError, error } = validateInput(name, value, formState)
    let isFormValid = true
    for (const key in formState) {
        const item = formState[key]
        if (key === name && hasError) {
            isFormValid = false
            break
        } else if (key !== name && item.hasError) {
            isFormValid = false
            break
        }
    }
    dispatch({
        type: UPDATE_FORM,
        data: { name, value, hasError, error, touched: true, isFormValid },
    })
}