import React from 'react';
import Header from '../../navbar/Navbar';
import Sidebar from '../Sidebar';
import { useLocation } from 'react-router-dom';
import useUserAuth from '../../hooks/useUserAuth';

const Layout = ({ children }) => {
    const {isLogin} = useUserAuth();
    const location = useLocation();
    if(isLogin){
        return (
            <div className='flex w-[100%] overflow-hidden flex-col md:flex-row'>
                <div className=''>
                    <Sidebar /> 
                </div>
                <div className='w-full'>
                    <Header />
                    <div className={` h-[calc(100%_-_100px)] ${location.pathname === "/dashboardLogin" ? "bg-[transparent]" : ""}`}>
                        {children}
                    </div>
                </div>
            </div>
        )
    }else{
        return <div className='flex w-[100%] h-[100vh] xl:overflow-y-auto'>{children}</div>
    }
}

export default Layout;
