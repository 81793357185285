import React from 'react';

const Modal = ({ closeModal, message, description , button}) => {
    return (
        <>
            <div className='overlay' onClick={closeModal}></div>
            <div className='modal bg-[#fff] shadow-[0px_4px_35px_32px_rgba(0_0_0_0.01)] w-[473px]  p-[30px] rounded-[20px] max-[576px]:w-[90%] max-[576px]:p-[15px]'>
                <span className="text-[18px] font-medium leading-[27px] block text-center text-[##1E243B]" >{message}</span> <br />
                <span className='text-[14px] weight-300 text-center text-[#1E243B]'>{description}</span>
                <div className="flex justify-center mt-[30px] gap-[10px]">
                    <button className="border-[2px] border-[#1e243b] border-[solid] text-[#1e243b] leading-[21px] text-[14px] font-semibold  w-[110px] h-[40px] rounded-[50px]" onClick={closeModal}>Cancel</button>
                    <button className="border-[2px] border-[#1e243b] border-[solid] text-[#fff] bg-[#1e243b] leading-[21px] text-[14px] font-semibold  w-[110px] h-[40px] rounded-[50px]">{button}</button>
                </div>
            </div>

        </>
    )
}

export default Modal;
