import './App.css';
import { Routes, Route } from "react-router-dom"
import { Navigate } from "react-router-dom";

import Home from './pages/Home';
import Chat from './pages/Chat';
import Job from './pages/Job';
import Saved from './pages/Saved';
import PostForm from './pages/PostJob';
import EditJob from './pages/EditJob';
import Login from './pages/Login'
import {paths} from './utils/paths';
import PrivateRoute from './component/helper/PrivateRoute';
import DeshboardSignup from './pages/DeshboardSignup';
import DeshboardLogin from './pages/DeshboardLogin';
import LoginRegister from './pages/LoginRegister';
import DashboardLoginOTP from './pages/DashboardLoginOTP';
import RecruiteJobSeeker from './pages/RecruiteJobSeeker';
import Signup from './pages/Signup';
import Availabilty from './pages/Availabilty';
import PostJobForSeeker from './pages/PostJobForSeeker';
import ChatForSeeker from './pages/ChatForSeeker';
import EditJobSeeker from './pages/EditJobSeeker'; 
import ProfileSeeker from './pages/ProfileSeeker'; 
import AllJobsForSeeker from './pages/AllJobsForSeeker'; 
import SignUpHR from './pages/SignUpHR';
import AvailabiltyHR from './pages/AvailabiltyHR';
import ProfileHR from './pages/ProfileHR';
import EmailLogin from './pages/EmailLogin';


function App() {
  return (
    <Routes>
      <Route path="/" element={<DeshboardSignup />}  />
      {/* <Route path={paths.dashboardSignup} element={<DeshboardSignup />} /> */}
      <Route path={paths.login} element={<Login />} />
      <Route path={paths.signup} element={<Signup />} />
      <Route path={paths.emailLogin} element={<EmailLogin />} />
      <Route path={paths.signUpHR} element={<SignUpHR />} />
      <Route path={paths.recruiteJobSeeker} element={<RecruiteJobSeeker />} />
      <Route path={paths.availabilty} element={<Availabilty />} />
      <Route path={paths.postJobForSeeker} element={<PostJobForSeeker />} />
      <Route path={paths.editJobSeeker} element={<EditJobSeeker />} />
      <Route path={paths.availabiltyHR} element={<AvailabiltyHR />} />
      <Route path={paths.profileHR} element={<ProfileHR />} />
      <Route path={paths.dashboardLogin} element={<DeshboardLogin />} />
      <Route path={paths.loginRegister} element={<LoginRegister />} />
      <Route path={paths.dashboardLoginOTP} element={<DashboardLoginOTP />} />


      <Route path={paths.dashboard} element={<Home />} />
      <Route path={paths.chat} element={ <Chat />} />
      <Route path={paths.allJobsForSeeker} element={ <AllJobsForSeeker />} />
      <Route path={paths.profileSeeker} element={ <ProfileSeeker />} />
      <Route path={paths.chatForSeeker} element={ <ChatForSeeker />} />
      <Route path={paths.jobs} element={ <Job /> } />
      <Route path={paths.savedJobs} element={ <Saved /> } />
      <Route path={paths.postJobs} element={ <PostForm /> } />
      <Route path={paths.editJobs} element={<PrivateRoute><EditJob /></PrivateRoute>} />
     
    </Routes> 
  );
}

export default App;
