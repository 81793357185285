import React from "react";
function Reciever(props) {
  return (
    <div>
      <div className="flex justify-end w-[60%] ml-[auto] max-[1500px]:w-[100%]">
        <div className="max-[1200px]:w-[100%]">
          <div
            className="mt-[15px] bg-[#F5FBD6] p-[15px] rounded-[20px] rounded-tr-[0px] text-[#1E243B]
                    leading-[20px] text-[13px] font-normal max-w-[fit-content]"
          >
            {" "}
            {props.message}
          </div>
        </div>
        <div className="w-[45px] h-[45px] ml-[8px]">
          <img
            className="rounded-full w-[100%]"
            src={props.ProfileimageUrl}
            alt={props.ProfileimageAlt}
          />
        </div>
      </div>
      <div className="flex justify-end gap-[8px] items-center mt-[8px]">
        <span className="w-[15px] h-[15px]">
          <img
            className="w-[100%]"
            src={props.SeenimageUrl}
            alt={props.SeenimageAlt}
          />
        </span>
        <span className="text-[#A3A3A3] leading-[18px] text-[12px] font-medium">
          {props.time}
        </span>
      </div>
    </div>
  );
}

export default Reciever;
