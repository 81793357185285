import React, { useState, useReducer, useEffect } from "react";
import classNames from "classnames";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import {
  onFocusOut,
  onInputChange,
  UPDATE_FORM,
  // UPDATE_VALIDITY,
  validateInput,
} from "../component/lib/form/navbarForm";

const initialState = {
  role: { value: "", touched: false, hasError: true, error: "" },
  location: { value: "", touched: false, hasError: true, error: "" },
  isFormValid: false,
};

const formsReducer = (state, action) => {
  switch (action.type) {
    case UPDATE_FORM:
      const { name, value, hasError, error, touched, isFormValid } =
        action.data;
      return {
        ...state,
        [name]: { ...state[name], value, hasError, error, touched },
        isFormValid,
      };
    default:
      return state;
  }
};

const Header = () => {
  const [toggle, setToggle] = useState(false);
  const [formState, dispatch] = useReducer(formsReducer, initialState);
  const location = useLocation();
  const [locations, setLocations] = useState([]);
  const [Roles, setRoles] = useState([]);

  const toggleHandler = () => {
    setToggle(!toggle);
  };

  const handleLogout = () => {
    console.log("Logout functionality triggered");
    setToggle(false);
  };

  console.log("location>>", location.pathname);
  const confirmValidity = (formState, dispatch) => {
    let isFormValid = true;
    for (let name in formState) {
      const item = formState[name];
      const { value } = item;
      const { hasError, error } = validateInput(name, value, formState);
      if (hasError) {
        isFormValid = false;
      }
      if (name) {
        dispatch({
          type: UPDATE_FORM,
          data: {
            name,
            value,
            hasError,
            error,
            touched: true,
            isFormValid,
          },
        });
      }
    }
  };

  useEffect(() => {
    const fetchRole = async () => {
      try {
        const response = await fetch(
          "https://api-letshireme.onrender.com/api/v1/getAllSkillSets"
        );
        const data = await response.json();
        setRoles(data);
      } catch (error) {
        console.error("Error fetching Roles:", error);
      }
    };
    fetchRole();
  }, []);

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const response = await fetch(
          "https://api-letshireme.onrender.com/api/v1/locations"
        );
        const data = await response.json();
        setLocations(data.data);
      } catch (error) {
        console.error("Error fetching locations:", error);
      }
    };

    fetchLocations();
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    confirmValidity(formState, dispatch, validateInput);
    if (formState.isFormValid) {
      console.log("success");
      console.log("Role:", formState.role.value);
      console.log("Location:", formState.location.value);
    } else {
      console.log("invalid");
    }
  };

  const handleBrowseClick = (e) => {
    e.preventDefault();
    onSubmit(e);
  };

  return (
    <div
      className={`${
        location.pathname === "/availabilty" ||
        location.pathname === "/signup" ||
        location.pathname === "/recruiteJobSeeker" ||
        location.pathname === "/dashboardLoginOTP" ||
        location.pathname === "/loginRegister" ||
        location.pathname === "/dashboardLogin" ||
        location.pathname === "/dashboardSignup" ||
        location.pathname === "/SignUpHR" ||
        location.pathname === "/availabiltyHR" ||
        location.pathname === "/login" ||
        location.pathname === "/profileHR" ||
        location.pathname === "/emailLogin" ||
        location.pathname === "/"
          ? "hidden"
          : ""
      }`}
    >
      {location.pathname !== "/profileSeeker" && (
        <div className="flex items-center flex-col md:flex-row md:ml-[38px] justify-between px-[15px] md:px-[0] pb-[5px] md:pb-[0px]">
          <div></div>
          <form
            onSubmit={onSubmit}
            className={` w-[100%] md:max-w-[476px] lg:max-w-[660px] flex-col md:flex-row h-[84px] md:h-[60px] border-2 border-[rgba(30, 36, 59, 0.10);] rounded-[8px] md:rounded-[45px] flex items-center justify-around ${
              (location.pathname === "/availabilty" ? "hidden" : "",
              Roles.length > 0
                ? "w-[100%] md:max-w-[476px] lg:max-w-[660px]"
                : "w-[100%] md:max-w-[476px] lg:max-w-[660px]")
            }`}
          >
            <div className="flex w-[100%] md:w-[35%] items-center px-[6px] md:px-[0px] md:justify-start gap-[4px]">
              <img
                className="profile_image"
                src="images/role1.png"
                alt=""
                width={22}
                height={22}
                quality={100}
              />
              <input
                className={classNames(
                  "outline-none bg-[transparent] text-[#000000b0] text-[16px] font-play font-[500] landing-[24px] w-[190px]",
                  formState.role.touched && formState.role.hasError
                    ? "border-red-600"
                    : "border-off-white"
                )}
                value={formState.role.value}
                placeholder="Role"
                onChange={(e) => {
                  onInputChange("role", e.target.value, dispatch, formState);
                }}
                onBlur={(e) => {
                  onFocusOut("role", e.target.value, dispatch, formState);
                }}
              ></input>
              <div className="md:absolute md:top-10 flex justify-end md:justify-start">
                {formState.role.touched && formState.role.hasError && (
                  <div className="info-text absolute md:top-[30px] lg:top-[12px] lg:left-[8px] md:w-[300px] mt-[12px] lg:mt-[1.75rem] lg:text-[15px] lg:ml-0 ml-2 text-red-600">
                    {formState.role.error}
                  </div>
                )}
              </div>
            </div>
            <div className="w-[100%] h-[2px] bg-[#e5e7eb] text-[white] md:hidden">
              w
            </div>
            <div className="flex w-[100%] md:w-[35%] px-[6px] md:px-[0px] md:justify-start items-center gap-[4px] md:before:content-[''] md:before:w-[1px] md:before:h-[28px] md:before:bg-[rgb(229_231_235)] md:before:relative md:before:left-[-6px]">
              <img
                className="profile_image"
                src="images/location1.png"
                alt=""
                width={22}
                height={22}
              />
              <input
                id="locationDropdown"
                placeholder="Location"
                className={classNames(
                  "outline-none bg-[transparent] text-[#000000b0] text-[16px] font-play font-[500] landing-[24px] w-[190px]",
                  formState.location.touched && formState.location.hasError
                    ? "border-red-600"
                    : "border-off-white"
                )}
                value={formState.location.value}
                onChange={(e) => {
                  onInputChange(
                    "location",
                    e.target.value,
                    dispatch,
                    formState
                  );
                }}
                onBlur={(e) => {
                  onFocusOut("location", e.target.value, dispatch, formState);
                }}
              ></input>
              <div className="md:absolute md:top-10 flex justify-end md:justify-start">
                {formState.location.touched && formState.location.hasError && (
                  <div className="info-text absolute md:top-[30px] lg:top-[12px] md:w-[300px] mt-3 lg:mt-[1.75rem] lg:text-[15px] lg:ml-0 ml-2 text-red-600">
                    {formState.location.error}
                  </div>
                )}
              </div>
            </div>
            <div className="hidden md:block flex justify-center md:justify-end w-[50%] md:w-[22%] md:before:content-[''] md:before:w-[1px] md:before:h-[27px] md:before:bg-[rgb(229_231_235)] md:before:relative md:before:left-[-10px] md:before:top-[12px]">
              <button
                className="flex justify-center font-semibold text-[18px] gap-[7px] items-center md:w-[108px] lg:w-[150px] font-play text-white bottom-2.5 bg-[#1E243B] input04521 hover:bg-[#1E243B] focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-full md:text-[16px] lg:text-xl dark:bg-[#979baa] dark:hover:bg-darkblue dark:focus:ring-blue-800 md:mr-[-4px] lg:mr-[-7px] h-[40px] md:h-[50px] content-center"
              >
                <img
                  className="profile_image"
                  src="images/search1.png"
                  alt=""
                  width={22}
                  height={22}
                />
                Browse
              </button>
            </div>
          </form>
          <div className="mt-[15px] flex md:hidden justify-center md:justify-end w-[50%] md:w-[22%] md:before:content-[''] md:before:w-[1px] md:before:h-[27px] md:before:bg-[rgb(229_231_235)] md:before:relative md:before:left-[-10px] md:before:top-[12px]">
            <button
              onClick={handleBrowseClick}
              className="flex justify-center font-semibold text-[16px] gap-[7px] items-center w-[150px] font-play text-white bottom-2.5 bg-[#1E243B] input04521 hover:bg-[#1E243B] focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-[6px] md:text-[16px] lg:text-xl dark:bg-[#979baa] dark:hover:bg-darkblue dark:focus:ring-blue-800 md:mr-[-4px] lg:mr-[-7px] h-[40px] md:h-[50px] content-center"
            >
              Browse 
            </button>
          </div>
          <div className="flex h-[50px] md:h-[100px] bg-[#FFFFFF] hidden md:block">
            <div className="flex items-center gap-x-[6px] md:pr-[74px] lg:pr-[50px] md:pt-[24px] relative">
              <img
                className="profile_image"
                src="images/profile.png"
                alt=""
                width={55}
                height={55}
              />
              <span className="text-[#000] text-[14px] font-semibold leading-[21px] profile_name">
                Eva Smith
              </span>
              <div className="relative">
                <img
                  className="ml-[10px] left_dropdown_profile cursor-pointer"
                  src="images/drop-down.png"
                  alt=""
                  onClick={toggleHandler}
                />
                {toggle && (
                  <div className="absolute lg:top-full z-50 left-[] md:top-[38px] md:right-[-12px] lg:right-[-30px] bg-[#f5fbd6] w-[8rem] py-2 rounded-md shadow-md">
                    <span
                      className="block font-semibold px-4 py-2 text-gray-800 hover:bg-[#1e243b] hover:text-[white] cursor-pointer"
                      onClick={handleLogout}
                    >
                      Logout
                    </span>
                    <span className="block font-semibold px-4 py-2 text-gray-800 hover:bg-gray-200 cursor-pointer">
                      Option 2
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
