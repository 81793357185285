import React, { useState } from "react";

const DashboardLoginOTP = () => {
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [errorMessage, setErrorMessage] = useState("");

  const handleOtpChange = (index, value) => {
    if (value.match(/^[0-9]$/) || value === "") {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      setErrorMessage("");
    }
  };

  const handleContinue = () => {
    const isValid = otp.every((value) => value !== "");
    if (isValid) {
      console.log("Valid OTP:", otp.join(""));
      setErrorMessage("");
    } else {
      setErrorMessage("Please fill in all OTP fields.");
    }
  };

  return (
    <div className="flex justify-center items-center px-[10px] mt-[20px] md:mt-[0] md:h-[100vh]">
      <div className="w-[100%] max-w-[416px] p-[8px]">
        <div className="w-[100px] md:w-[120px] m-[auto]">
          <img src="images/otp-dash.png" alt="#" />
        </div> 
        <h2 className="text-[#1E243B] mt-[10px] text-[24px] font-semibold text-center sm:text-[32px]">
          Enter <span className="text-[#A8C523]">OTP code</span>
        </h2>
        <p className="text-[#6F7780] text-center text-[16px] mt-[12px] font-normal sm:text-[20px]">
          Please enter the OTP code sent to your
          <br />
          mobile number +91 9999988888
        </p>
        <div className="mt-[40px] sm:mt-[62px]">
          <div className="flex justify-center items-center gap-[12px]">
            {otp.map((value, index) => (
              <input
                key={index}
                className="px-[8px] py-[8px] w-[75px] h-[60px] text-center rounded-[10px] border-[#6F7780] border border-solid"
                type="text"
                maxLength="1"
                value={value}
                onChange={(e) => handleOtpChange(index, e.target.value)}
              />
            ))}
          </div>
          <p className="text-red-500 text-[16px] text-center mt-[12px]">{errorMessage}</p>
          <br />
          <button
            className="border-[#1E243B] border mt-[10px] border-solid rounded-[10px] w-[100%] text-[18px] bg-[#1E243B] py-[8px] font-semibold text-[#fff]"
            onClick={handleContinue}
          >
            Continue
          </button>
        </div>
        <p className="text-[#6F7780] sm:mt-[30px] mt-[20px] text-[18px] font-semibold text-center">
          Did you not receive OTP ?{" "}
          <span className="text-[#A8C523]">Resend OTP</span>
        </p>
      </div>
    </div>
  );
};

export default DashboardLoginOTP;
