import React from "react";

const ChatDetailCard = ({
  jobRoll,
  image,
  experience,
  name,
  description,
  location,
  jobType,
  education,
  salary,
}) => {
  const yourSkill = {
    skills: [
      "HTML",
      "JavaScript",
      "CSS",
      "React",
      "Node.js",
      "Figma",
      "Photoshop",
    ],
  };
  const yourList = {
    items: [
      {
        id: 1,
        text: "  Problem-solving information technology specialist with two years of experience using operating and security software for varioustechnology companies",
      },
      {
        id: 2,
        text: " Provided troubleshooting and testing services that enhanced company productivity by 20% in six months",
      },
      {
        id: 3,
        text: "Seeking a software company to apply my networking and digital forensics knowledge",
      },
    ],
  };
  return (
    <div className=" border-l-[1px] p-[20px] border-solid border-[30px_36px_59px_0.12] overflow-y-auto h-[calc(100%_-_0px)] custom_scroll_bar max-[1200px]:hidden">
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-[10px]">
          <div className="w-[55px] h-[55px] rounded-full">
            <img className="w-full" src={image} alt="#" />
          </div>
          <div className="div">
            <span className="block text-[16px] text-[#1E243B] leading-[26px] font-semibold">
              {jobRoll}
            </span>
            <span className="block text-[16px] text-[#1E243B] leading-[26px] font-normal">
              {name}
            </span>
          </div>
        </div>
        <button className="bg-[#1E243B] border-2 border-solid border-[#1E243B] rounded-[50px] font-semibold text-[#fff] w-[110px] h-[40px]">
          Chat Now
        </button>
      </div>
      <div className="mt-[20px] mb-[20px]">
        <span className="text-[14px] text-[#1E243B] leading-[24px] font-normal">
          About Profile
        </span>
        <ul className="text-[14px] text-[#1E243B] leading-[24px] font-normal pl-[20px] list-disc opacity-[0.7]">
          {yourList.items.map((item) => (
            <li key={item.id}>{item.text}</li>
          ))}
        </ul>
      </div>
      <div className="mt-[20px] mb-[20px]">
        <span className="text-[14px] text-[#1E243B] leading-[24px] font-normal block">
          Work Experience
        </span>
        <span className="text-[14px] text-[#1E243B] leading-[24px] font-normal block opacity-[0.7]">
          {experience}
        </span>
      </div>
      <div className="mt-[20px] mb-[20px]">
        <span className="text-[14px] text-[#1E243B] leading-[24px] font-normal block">
          Location
        </span>
        <span className="text-[14px] text-[#1E243B] leading-[24px] font-normal block opacity-[0.7]">
          {location}
        </span>
      </div>
      <div className="mt-[20px] mb-[20px]">
        <span className="text-[14px] text-[#1E243B] leading-[24px] font-normal block">
          Job Type
        </span>
        <span className="text-[14px] text-[#1E243B] leading-[24px] font-normal block opacity-[0.7]">
          {jobType}
        </span>
      </div>
      <div className="mt-[20px] mb-[20px]">
        <span className="text-[14px] text-[#1E243B] leading-[24px] font-normal block">
          Education
        </span>
        <span className="text-[14px] text-[#1E243B] leading-[24px] font-normal block opacity-[0.7]">
          {education}
        </span>
      </div>
      <div className="mt-[20px] mb-[20px]">
        <span className="text-[14px] text-[#1E243B] leading-[24px] font-normal block">
          Salary Expectation
        </span>
        <span className="text-[14px] text-[#1E243B] leading-[24px] font-normal block opacity-[0.7]">
          {salary}
        </span>
      </div>
      <div className="mt-[20px] mb-[20px]">
        <span className="text-[14px] text-[#1E243B] leading-[24px] font-normal block">
          Skills
        </span>
        <div className="mt-[15px] flex flex-wrap gap-[10px] mb-[20px]">
          {yourSkill.skills.map((skill, index) => (
            <div className="bg-[#F5FBD6] h-[40px] text-[#1E243B] leading-[18px] font-normal px-[30px] py-[8px] flex justify-center items-center rounded-full w-[fit-content]">
              <div key={index}>
                {skill}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="mt-[20px] mb-[20px]">
        <button className="flex items-center justify-center gap-[6px]">
          <div className="w-[16px] h-[16px]">
            <img src="images/download_resume.png" alt="" />
          </div>
          <span className="text-[14px] text-[#1E243B] leading-[24px] font-normal">
            {" "}
            Resume
          </span>
        </button>
      </div>
    </div>
  );
};

export default ChatDetailCard;
