export const paths = {
    login: "/login",
    dashboard: "/home",
    messages: "/messages",
    jobs: "/jobs",
    savedJobs: "/saved-jobs",
    postJobs: "/post-job",
    editJobs: "/edit-jobs",
    chat:"/chat",
    dashboardSignup:"/dashboardSignup",
    dashboardLogin:"/dashboardLogin",
    loginRegister:"/loginRegister",
    dashboardLoginOTP:"/dashboardLoginOTP",
    recruiteJobSeeker:"/recruiteJobSeeker",
    signup:"/signup",
    availabilty:"/availabilty",
    postJobForSeeker:"/postJobForSeeker",
    chatForSeeker:"/chatForSeeker",   
    editJobSeeker:"/editJobSeeker",  
    profileSeeker:"/profileSeeker", 
    allJobsForSeeker:"/allJobsForSeeker",
    dashboardOrSignup:"/dashboardOrSignup",
    signUpHR:"/signUpHR",
    availabiltyHR:'/availabiltyHR',
    profileHR:"/profileHR",
    emailLogin:"emailLogin"
}


export const APIBASEURL = "https://api.letshireme.com/v1";