export const UPDATE_FORM = "UPDATE_FORM";

export const validateInput = (name, value, formState) => {
    let hasError = false,
        error = ""
    switch (name) {
        case "designation":
            if (value === "") {
                hasError = true
                error = "Designation can't be empty"
            } else {
                hasError = false
                error = ""
            }
            break;
        case "names":
            if (value.trim() === "") {
                hasError = true
                error = "Name can't be empty"
            } else if (value.trim().length < 3) {
                hasError = true
                error = "Name should be more than 3 characters"
            } else {
                hasError = false
                error = ""
            }
            break;
        case "email":
            if (value.trim() === "") {
                hasError = true;
                error = "Email can't be empty";
            } else if (
                !/^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/.test(
                value
                )
            ) {
                hasError = true;
                error = "Invalid Email";
            } else {
                hasError = false;
                error = "";
            }
            break;
    }
    return { hasError, error }
}

export const onInputChange = (name, value, dispatch, formState) => {
    const { hasError, error } = validateInput(name, value, formState)
    let isFormValid = true

    for (const key in formState) {
        const item = formState[key]
        // Check if the current field has error
        if (key === name && hasError) {
            isFormValid = false
            break
        } else if (key !== name && item.hasError) {
            // Check if any other field has error
            isFormValid = false
            break
        }
    }
    dispatch({
        type: UPDATE_FORM,
        data: { name, value, hasError, error, touched: false, isFormValid },
    })
}

export const onFocusOut = (name, value, dispatch, formState) => {
    const { hasError, error } = validateInput(name, value, formState)
    let isFormValid = true
    for (const key in formState) {
        const item = formState[key]
        if (key === name && hasError) {
            isFormValid = false
            break
        } else if (key !== name && item.hasError) {
            isFormValid = false
            break
        }
    }
    dispatch({
        type: UPDATE_FORM,
        data: { name, value, hasError, error, touched: true, isFormValid },
    })
}