// PrivateRoute.js
import React from 'react';
import {Routes, Route, Navigate  } from 'react-router-dom';
import useUserAuth from '../../hooks/useUserAuth';


const PrivateRoute = ({children}) => {
    const { user, isLogin } = useUserAuth();
    console.log(user, isLogin);

    if(isLogin === false){
        return <Navigate to="/login" replace={true} />
    }
    if(isLogin === true){
        return children;
    }
    // return isLogin ? (
        
    // ) : (
    //     <Navigate to="/login" replace={true} />
    // );
};

export default PrivateRoute;