import React, { useState, useReducer, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import {
  onFocusOut,
  onInputChange,
  UPDATE_FORM,
  validateInput,
} from "../component/lib/form/SignUpHrForm";

import classNames from "classnames";

const initialState = {
  names: { value: "", touched: false, hasError: true, error: "" },
  number: { value: "", touched: false, hasError: true, error: "" },
  email: { value: "", touched: false, hasError: true, error: "" },
  designation: { value: "", touched: false, hasError: true, error: "" },
  isFormValid: false,
};

const formReducer = (state, action) => {
  switch (action.type) {
    case UPDATE_FORM:
      const { name, value, hasError, error, touched, isFormValid } =
        action.data;
      return {
        ...state,
        [name]: { ...state[name], value, hasError, error, touched },
        isFormValid,
      };
    default:
      return state;
  }
};

const SignUpHR = () => {
  const [formState, dispatch] = useReducer(formReducer, initialState);
  const [selectedJobType, setSelectedJobType] = useState("");
  const [error, setError] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [selectField, setSelectField] = useState("");
  const navigate = useNavigate();

  const confirmValidity = (formState, dispatch) => {
    let isFormValid = true;
    for (let name in formState) {
      const item = formState[name];
      const { value } = item;
      const { hasError, error } = validateInput(name, value, formState);
      if (hasError) {
        isFormValid = false;
      }
      if (name) {
        dispatch({
          type: UPDATE_FORM,
          data: {
            name,
            value,
            hasError,
            error,
            touched: true,
            isFormValid,
          },
        });
      }
    }
  };

  useEffect(()=> {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      phoneNumber: formState.number.value,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://api.letshireme.com/v1/signup/check-existance",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === 200 && result.data.valueExist) {
          setError("Phone number already exists in the database.");
        } else {
          setError(""); 
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [formState.number.value]);
  

  useEffect(() => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      email: formState.email.value,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://api.letshireme.com/v1/signup/check-existance",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === 200 && result.data.valueExist) {
          setErrorEmail("Email Already exists in database.");
        } else {
          setErrorEmail(""); 
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [formState.email.value]);

  const onSubmit = (e) => {
    e.preventDefault(); // Form submission prevent karna
    confirmValidity(formState, dispatch, validateInput); // Form ki validity ko confirm karna

    if (formState.isFormValid) {
      // Agar form valid hai
      // FormData object banayein
      const formData = {
        name: formState.names.value,
        email: formState.email.value,
        phoneNumber: formState.number.value,
        // company: formState.company.value,
        role: "RECRUITER",
        designation: formState.designation.value,
      };

      fetch("https://api.letshireme.com/v1/recruiter/signup", {
        // API endpoint par POST request bhejein
        method: "POST", // Method POST ka use karein
        headers: {
          "Content-Type": "application/json", // JSON format mein data bhejein
        },
        body: JSON.stringify(formData), // FormData ko JSON format mein convert karein
      })
        .then((response) => {
          if (response.status !== 200) {
            throw new Error(response.message);
          }
          return response.json(); // Response ko JSON format mein parse karein
        })
        .then((result) => {
          console.log("Success:", result); // Success message console mein print karein
          // Navigate or perform other actions upon successful signup
          navigate("/availabiltyHR");
        })
        .catch((error) => {
          console.error("Error:", error); // Error ko handle karein
          // Handle errors here, e.g., display an error message to the user
        });
    }
  };

  return (
    <div className="flex flex-col justify-start items-center px-[10px] sm:h-[100vh] w-[100%]">
      <div className="p-[12px] w-[100%]">
        <Link to="/">
          <img
            src="images/greenDB.png"
            alt="#"
            className="w-[176px] sm:w-[223px] h-[60px] sm:h-[76px]"
          />
        </Link>
      </div>
      <div className="w-[100%] max-w-[560px] p-[8px] mt-[22px] sm:m-[auto]">
        <div className=" w-[60px] md:w-[75px] m-[auto]">
          <img src="images/job-seeker-one.png" alt="#" />
        </div>
        <h2 className="text-[#1E243B] text-[20px] font-semibold text-center sm:text-[32px]">
          You Are Sign Up As <span className="text-[#A8C523]">Recruiter</span>
        </h2>
        <div className="mt-[8px] sm:mt-[62px]">
          <div className="flex gap-[-0.25rem] sm:gap-3 flex-col sm:flex-row">
            <div className="w-[100%] sm:w-[50%] relative">
              <img
                className="absolute top-[32px] transform -translate-y-1/2 left-3 text-gray-500"
                src="images/mask_12.png"
                alt=""
              />
              <input
                placeholder="Your Name"
                className={classNames(
                  "form-control poppins-familybg-[#F8F8F9] rounded-[10px] h-[45px] px-[40px] py-[10px] mt-[10px] w-[100%] focus:outline-none border-[#6F7780] border border-solid",
                  formState.names.touched && formState.names.hasError
                    ? "border-red-600"
                    : "border-off-white"
                )}
                type="text"
                value={formState.names.value}
                onChange={(e) => {
                  onInputChange("names", e.target.value, dispatch, formState);
                }}
                onBlur={(e) => {
                  onFocusOut("names", e.target.value, dispatch, formState);
                }}
              />
              {formState.names.touched && formState.names.hasError && (
                <div className="error-msg">{formState.names.error}</div>
              )}
            </div>
            <div className="w-[100%] sm:w-[50%] relative">
              <img
                className="absolute top-[32px] transform -translate-y-1/2 left-3 text-gray-500"
                src="images/mask12_Phone.png"
                alt=""
              />
              <input
                placeholder="Your Phone Number"
                maxLength={10}
                className={classNames(
                  "form-control poppins-familybg-[#F8F8F9] rounded-[10px] h-[45px] px-[40px] py-[10px] mt-[10px] w-[100%] focus:outline-none border-[#6F7780] border border-solid",
                  formState.number.touched && formState.number.hasError
                    ? "border-red-600"
                    : "border-off-white"
                )}
                type="text"
                value={formState.number.value}
                onChange={(e) => {
                  onInputChange("number", e.target.value, dispatch, formState);
                }}
                onBlur={(e) => {
                  onFocusOut("number", e.target.value, dispatch, formState);
                }}
                onClick={() => setSelectField("number")}
              />
              {formState.number.touched && formState.number.hasError && (
                <div className="error-msg">{formState.number.error}</div>
              )}
            </div>
          </div>
          {error && <div className="text-red-600 mt-3 ml-2 md:text-right text-[14px]">{error}</div>}
          <div className="flex gap-[-0.25rem] sm:gap-3 flex-col sm:flex-row">
            {/* <div className="w-[100%] sm:w-[50%] relative">
              <img
                className="absolute top-[32px] transform -translate-y-1/2 left-3 text-gray-500"
                src="images/mask12_CD.png"
                alt=""
              />
              <input
                value={companySelected}
                placeholder="company Name"
                onChange={(e) => {
                  onInputChange(
                    "company",
                    setCompanySelected(e.target.value),
                    dispatch,
                    formState
                  );
                }}
                className={classNames(
                  "form-control poppins-familybg-[#F8F8F9] text-[#7e7b7b] rounded-[10px] h-[45px] px-[40px] py-[10px] mt-[10px] w-[100%] focus:outline-none border-[#6F7780] border border-solid",
                  formState.company &&
                    formState.company.touched &&
                    formState.company.hasError
                    ? "border-red-600"
                    : "border-off-white"
                )}
                onBlur={(e) => {
                  onFocusOut("company", e.target.value, dispatch, formState);
                }}
                id="myDropdown"
                name="myDropdown"
              />
              {formState.company &&
                formState.company.touched &&
                formState.company.hasError && (
                  <div className="error-msg">{formState.company.error}</div>
                )}
            </div> */}
            <div className="w-[100%] sm:w-[50%] relative">
              <img
                className="absolute top-[32px] transform -translate-y-1/2 left-3 text-gray-500"
                src="images/mask12_CD.png"
                alt=""
              />
              <select
                value={selectedJobType}
                onChange={(e) => {
                  onInputChange(
                    "designation",
                    setSelectedJobType(e.target.value),
                    dispatch,
                    formState
                  );
                }}
                className={classNames(
                  "form-control text-[#7e7b7b] poppins-familybg-[#F8F8F9] rounded-[10px] h-[45px] indent-[40px] py-[10px] mt-[10px] w-[100%] focus:outline-none border-[#6F7780] border border-solid",
                  formState.designation &&
                    formState.designation.touched &&
                    formState.designation.hasError
                    ? "border-red-600"
                    : "border-off-white"
                )}
                onBlur={(e) => {
                  onFocusOut(
                    "designation",
                    e.target.value,
                    dispatch,
                    formState
                  );
                }}
                id="myDropdown"
                name="myDropdown"
              >
                <option value="" disabled>
                  Designation
                </option>
                <option value="HR">HR</option>
                <option value="Founder/CEO">Founder/CEO</option>
                <option value="CTO">CTO</option>
                <option value="CMO">CMO</option>
                <option value="COO">COO</option>
                <option value="Others">Others</option>
              </select>
              {formState.designation &&
                formState.designation.touched &&
                formState.designation.hasError && (
                  <div className="error-msg">{formState.designation.error}</div>
                )}
            </div>
            <div className="w-[100%] sm:w-[50%] relative">
              <img
                className="absolute top-[32px] transform -translate-y-1/2 left-3 text-gray-500"
                src="images/mask12_email.png"
                alt=""
              />
              <input
                type="email"
                placeholder="Official Email"
                className={classNames(
                  "form-control poppins-familybg-[#F8F8F9] rounded-[10px] h-[45px] px-[42px] py-[10px] mt-[10px] w-[100%] focus:outline-none border-[#6F7780] border border-solid",
                  formState.email.touched && formState.email.hasError
                    ? "border-red-600"
                    : "border-off-white"
                )}
                value={formState.email.value}
                onChange={(e) => {
                  onInputChange("email", e.target.value, dispatch, formState);
                }}
                onBlur={(e) => {
                  onFocusOut("email", e.target.value, dispatch, formState);
                }}
                onClick={() => setSelectField("email")}
              />
              {formState.email.touched && formState.email.hasError && (
                <div className="info-text mt-3 ml-2 text-red-600">
                  {formState.email.error}
                </div>
              )}
            </div>
          </div>
          {errorEmail && (
            <div className="text-red-600 mt-3 ml-2 md:text-right text-[14px] md:pr-[32px]">{errorEmail}</div>
          )}
          <div className="flex gap-3">
            {/* <div className="w-[100%] relative">
              <img
                className="absolute top-[32px] transform -translate-y-1/2 left-3 text-gray-500"
                src="images/mask12_email.png"
                alt=""
              />
              <input
                type="email"
                placeholder="Official Email"
                className={classNames(
                  "form-control poppins-familybg-[#F8F8F9] rounded-[10px] h-[45px] px-[42px] py-[10px] mt-[10px] w-[100%] focus:outline-none border-[#6F7780] border border-solid",
                  formState.email.touched && formState.email.hasError
                    ? "border-red-600"
                    : "border-off-white"
                )}
                value={formState.email.value}
                onChange={(e) => {
                  onInputChange("email", e.target.value, dispatch, formState);
                }}
                onBlur={(e) => {
                  onFocusOut("email", e.target.value, dispatch, formState);
                }}
              />
              {formState.email.touched && formState.email.hasError && (
                <div className="info-text mt-3 ml-2 text-red-600">
                  {formState.email.error}
                </div>
              )}
            </div> */}
            {/* <div className="w-[50%] relative">
              <img
                  className="absolute top-[32px] transform -translate-y-1/2 left-3 text-gray-500"
                  src="images/mask12_email.png"
                  alt=""
                />
                <select
                  value={roleSelected}
                  onChange={(e) => {
                    onInputChange(
                      "role",
                      setRoleSelected(e.target.value),
                      dispatch,
                      formState
                    );
                  }}
                  className={classNames(
                    "form-control text-[#7e7b7b] poppins-familybg-[#F8F8F9] rounded-[10px] h-[45px] px-[40px] py-[10px] mt-[10px] w-[100%] focus:outline-none border-[#6F7780] border border-solid",
                    formState.role &&
                      formState.role.touched &&
                      formState.role.hasError
                      ? "border-red-600"
                      : "border-off-white"
                  )}
                  onBlur={(e) => {
                    onFocusOut("role", e.target.value, dispatch, formState);
                  }}
                  id="myDropdown"
                  // name="myDropdown"
                >
                  <option value="" disabled>Role</option>
                  <option value="RECRUITER">RECRUITER</option>
                </select>
                {formState.role &&
                  formState.role.touched &&
                  formState.role.hasError && (
                    <div className="error-msg">{formState.role.error}</div>
                  )}
              </div> */}
          </div>
          <br />
          <div className="max-w-[370px] m-[auto]">
            <button
              onClick={onSubmit}
              className="border-[#1E243B] border mt-[10px] sm:mt-[34px] border-solid rounded-[10px] w-[100%] text-[18px] bg-[#1E243B] py-[8px] font-semibold text-[#fff]"
            >
              Sign Up
            </button>
          </div>
        </div>
        <p className="text-[#6F7780] sm:mt-[30px] mt-[20px] text-[18px] font-semibold text-center">
          Already have an account?{" "}
          <Link to="/login">
            <span className="text-[#A8C523] cursor-pointer">Login</span>
          </Link>
        </p>
      </div>
    </div>
  );
};

export default SignUpHR;
