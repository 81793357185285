import React, { useState, useReducer } from 'react';
import { onFocusOut, onInputChange, UPDATE_FORM, validateInput } from '../component/lib/form/home'
import classNames from "classnames";

const initialState = {
    company: { value: "", touched: false, hasError: true, error: "" },
    jobLocation: { value: "", touched: false, hasError: true, error: "" },
    description: { value: "", touched: false, hasError: true, error: "" },
    skill: { value: "", touched: false, hasError: true, error: "" },
    jobtitle: { value: "", touched: false, hasError: true, error: "" },
    jobtype: { value: "", touched: false, hasError: true, error: "" },
    experience: { value: "", touched: false, hasError: true, error: "" },
    education: { value: "", touched: false, hasError: true, error: "" },
    salary1: { value: "", touched: false, hasError: true, error: "" },
    salary2: { value: "", touched: false, hasError: true, error: "" },
    isFormValid: false,
}

const formReducer = (state, action) => {
    switch (action.type) {
        case UPDATE_FORM:
            const { name, value, hasError, error, touched, isFormValid } = action.data
            return {
                ...state,
                [name]: { ...state[name], value, hasError, error, touched },
                isFormValid,
            }
        default:
            return state
    }
}

const confirmValidity = (formState, dispatch) => {
    let isFormValid = true;
    for (let name in formState) {
        const item = formState[name]
        const { value } = item
        const { hasError, error } = validateInput(name, value, formState)
        if (hasError) {
            isFormValid = false
        }
        if (name) {
            dispatch({
                type: UPDATE_FORM,
                data: {
                    name,
                    value,
                    hasError,
                    error,
                    touched: true,
                    isFormValid,
                },
            })
        }
    }
}

const EditJob = () => {
    const [jobPostForm, dispatchForm] = useReducer(formReducer, initialState);
    const [notPostedYet, setNotPostedYet] = useState(true);
    const [selectedJob, setSelectedJob] = useState('');
    const [selectedJobType, setSelectedJobType] = useState('');
    const [selectedJobExperience, setSelectedJobExperience] = useState('');
    const [education, setEducation] = useState('');
    const [initialSalary, setInitialSalary] = useState('');
    const [finalSalary, setFinalSalary] = useState('');
    const [skill, setSkill] = useState('');
    const [skillList, setSkillList] = useState([]);
    const [validateCourseList, setValidateCourseList] = useState(false)
    

    const onSubmit = (e) => {
        e.preventDefault();
        confirmValidity(jobPostForm, dispatchForm);
        if (jobPostForm.isFormValid) {
            console.log(jobPostForm);
        } else {
            console.log("Form is invalid");
        }
        if (skillList.length === 0) {
            setValidateCourseList(true)
            console.log("list is  empty>>>", validateCourseList, skillList.length)
        } else {
            setValidateCourseList(false)
            console.log("list is not empty>>>", validateCourseList, skillList.length)
        }
    };


    const addSkillHandler = (e) => {
        e.preventDefault();
        if (skill.trim() !== '') {
            setSkillList([...skillList, skill]);
            setSkill('');
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            addSkillHandler(e);
        }
    };

    const removeSkillHandler = (index) => {
        const updatedList = skillList.filter((_, i) => i !== index);
        setSkillList(updatedList);
    };
    return (
        <div className="main_content w-full z-[0] relative pt-[30px] h-[100%]">
            <div className="content px-[15px] main-content_inner h-[100%]">
                <div className="main-content_width max-w-[991px] mx-[auto] flex flex-wrap justify-between pb-[30px] h-[100%]">
                    <div className="heading_info flex justify-between w-[100%]">
                        <span className="flex gap-x-[6px] items-center">
                            <img className="w-[22px] h-[22px]" src="images/back.png" alt="" />
                            <span className="text-[30px] text-[#1E243B] leading-[45px] font-normal">Edit Jobs</span>
                        </span>
                        <button className="border-2 border-solid border-[#1E243B] w-[120px] h-[50px] rounded-full font-medium text-[18px] leading-[27px] text-[#1E243B] max-[567px]:order-3 max-[567px]:mx-auto  max-[567px]:mt-[10px]" onClick={onSubmit}>Save</button>
                    </div>
                    <div className="border-2 w-full border-solid pb-[20px] border-[rgba(30_36_59_0.02)] p-[60px] mt-[20px] rounded-[20px] max-[1200px]:p-[20px] max-[567px]:p-[0px] max-[567px]:border-none h-[calc(85vh_-_78px)] overflow-y-auto">
                        <div className="flex gap-[50px] max-[991px]:flex-col max-[991px]:gap-[0px]">
                            <div className="w-[50%] max-[991px]:w-[100%]">
                                <div className="row mb-[20px]">
                                    <label className="text-[#1E243B] text-[16px] leading-[24px] font-normal block">Company</label>
                                    <input
                                        className={classNames(
                                            "form-control poppins-familybg-[#F8F8F9] rounded-[40px] h-[50px] px-[15px] py-[10px] mt-[10px] w-[100%] focus:outline-none border",
                                            jobPostForm.company.touched && jobPostForm.company.hasError ? "border-red-600" : "border-off-white"
                                        )}
                                        type="text"
                                        placeholder="company name"
                                        value={jobPostForm.company.value}
                                        onChange={e => {
                                            onInputChange("company", e.target.value, dispatchForm, jobPostForm);
                                        }}
                                        onBlur={e => {
                                            onFocusOut("company", e.target.value, dispatchForm, jobPostForm);
                                        }}
                                    />
                                    {jobPostForm.company.touched && jobPostForm.company.hasError && (
                                        <div className="error-msg">{jobPostForm.company.error}</div>
                                    )}
                                </div>
                                <div className="row mb-[20px]">
                                    <label className="text-[#1E243B] text-[16px] leading-[24px] font-normal block">Job Title</label>
                                    <select
                                        id="job-select"
                                        value={selectedJob}
                                        onChange={e => {
                                            onInputChange("jobtitle", setSelectedJob(e.target.value), dispatchForm, jobPostForm);
                                        }}
                                        className={classNames(
                                            "form-control poppins-familybg-[#F8F8F9] rounded-[40px] h-[50px] px-[15px] py-[10px] mt-[10px] w-[100%] focus:outline-none border",
                                            jobPostForm.jobtitle.touched && jobPostForm.jobtitle.hasError ? "border-red-600" : "border-off-white"
                                        )}
                                        onBlur={e => {
                                            onFocusOut("jobtitle", e.target.value, dispatchForm, jobPostForm);
                                        }}
                                    >
                                        <option value=""></option>
                                        <option value="web-developer">Web Developer</option>
                                        <option value="web-designer">Web Designer</option>
                                        <option value="graphic-designer">Graphic Designer</option>
                                        <option value="others">Others</option>
                                    </select>
                                    {jobPostForm.jobtitle.touched && jobPostForm.jobtitle.hasError && (
                                        <div className="error-msg">{jobPostForm.jobtitle.error}</div>
                                    )}
                                </div>  
                                <div className="row mb-[20px]">
                                    <label className="text-[#1E243B] text-[16px] leading-[24px] font-normal block">Job Type</label>
                                    <select
                                        id="job-select"
                                        value={selectedJobType}
                                        onChange={e => {
                                            onInputChange("jobtype", setSelectedJobType(e.target.value), dispatchForm, jobPostForm);
                                        }}
                                        className={classNames(
                                            "form-control poppins-familybg-[#F8F8F9] rounded-[40px] h-[50px] px-[15px] py-[10px] mt-[10px] w-[100%] focus:outline-none border",
                                            jobPostForm.jobtype.touched && jobPostForm.jobtype.hasError ? "border-red-600" : "border-off-white"
                                        )}
                                        onBlur={e => {
                                            onFocusOut("jobtype", e.target.value, dispatchForm, jobPostForm);
                                        }}
                                    >
                                        <option value=""></option>
                                        <option value="Full-time">Full Time</option>
                                        <option value="Part-time">Part Time</option>
                                    </select>
                                    {jobPostForm.jobtype.touched && jobPostForm.jobtype.hasError && (
                                        <div className="error-msg">{jobPostForm.jobtype.error}</div>
                                     )}
                                </div>
                                <div className="row mb-[20px]">
                                    <label className="text-[#1E243B] text-[16px] leading-[24px] font-normal block">Experience</label>
                                    <select
                                        id="job-select"
                                        value={selectedJobExperience}
                                        onChange={e => {
                                            onInputChange("experience", setSelectedJobExperience(e.target.value), dispatchForm, jobPostForm);
                                        }}
                                        className={classNames(
                                            "form-control poppins-familybg-[#F8F8F9] rounded-[40px] h-[50px] px-[15px] py-[10px] mt-[10px] w-[100%] focus:outline-none border",
                                            jobPostForm.experience.touched && jobPostForm.experience.hasError ? "border-red-600" : "border-off-white"
                                        )}
                                        onBlur={e => {
                                            onFocusOut("experience", e.target.value, dispatchForm, jobPostForm);
                                        }}
                                    >
                                        <option value=""></option>
                                        <option value="0-2">0-2 Years</option>
                                        <option value="1-3">1-3 years</option>
                                        <option value="2-5">2-5 years</option>
                                    </select>
                                    {jobPostForm.experience.touched && jobPostForm.experience.hasError && (
                                        <div className="error-msg">{jobPostForm.experience.error}</div>
                                    )}
                                </div>
                                <div className="row mb-[20px]">
                                    <label className="text-[#1E243B] text-[16px] leading-[24px] font-normal block">Job Location</label>
                                    <textarea
                                        className={classNames(
                                            "bg-[#F8F8F9] resize-none rounded-[15px] h-[85px] px-[15px] py-[10px] mt-[10px] w-[100%] focus:outline-none border",
                                            jobPostForm.jobLocation.touched && jobPostForm.jobLocation.hasError ? "border-red-600" : "border-off-white"
                                        )}
                                        type="text"
                                        placeholder="Your location"
                                        value={jobPostForm.jobLocation.value}
                                        onChange={e => {
                                            onInputChange("jobLocation", e.target.value, dispatchForm, jobPostForm);
                                        }}
                                        onBlur={e => {
                                            onFocusOut("jobLocation", e.target.value, dispatchForm, jobPostForm);
                                        }}
                                    />
                                            {jobPostForm.jobLocation.touched && jobPostForm.jobLocation.hasError && (
                                                <div className="error-msg">{jobPostForm.jobLocation.error}</div>
                                            )}
                                </div>
                                <div className="row mb-[20px]">
                                    <div className="flex items-center">
                                        <label htmlFor="toggle" className="toggle w-[50px] h-[25px]">
                                            <input id="toggle" type="checkbox" />
                                            <div className="toggle__slider"></div>
                                        </label>
                                        <span className="ml-[15px] text-[#1E243B] text-[16px] font-normal leading-[24px]">This is remote job</span>
                                    </div>
                                </div>
                            </div>
                            <div className="w-[50%] max-[991px]:w-[100%]">
                                <div className="row mb-[20px]">
                                    <label className="text-[#1E243B] text-[16px] leading-[24px] font-normal block">Education</label>
                                    <select
                                        id="job-select"
                                        value={education}
                                        onChange={e => {
                                            onInputChange("education", setEducation(e.target.value), dispatchForm, jobPostForm);
                                        }}
                                        className={classNames(
                                            "form-control poppins-familybg-[#F8F8F9] rounded-[40px] h-[50px] px-[15px] py-[10px] mt-[10px] w-[100%] focus:outline-none border",
                                            jobPostForm.education.touched && jobPostForm.education.hasError ? "border-red-600" : "border-off-white"
                                        )}
                                        onBlur={e => {
                                            onFocusOut("education", e.target.value, dispatchForm, jobPostForm);
                                        }}
                                    >
                                        <option value=""></option>
                                        <option value="Bthach">Btach</option>
                                        <option value="graduate">Graduate</option>
                                        <option value="post-graduate">Post-Graduate</option>
                                    </select>
                                    {jobPostForm.education.touched && jobPostForm.education.hasError && (
                                        <div className="error-msg">{jobPostForm.education.error}</div>
                                    )}
                                </div>
                                <div className="row mb-[20px]">
                                    <label className="text-[#1E243B] text-[16px] leading-[24px] font-normal block">Offered Salary</label>
                                    <div className="flex gap-x-[15px] w-full max-[480px]:flex-col max-[480px]:gap-y-[15px]">
                                        <div className='relative block w-full'>
                                            <select
                                                id="job-select"
                                                value={initialSalary}
                                                onChange={e => {
                                                    onInputChange("salary1", setInitialSalary(e.target.value), dispatchForm, jobPostForm);
                                                }}
                                                className={classNames(
                                                    "form-control poppins-familybg-[#F8F8F9] rounded-[40px] h-[50px] px-[15px] py-[10px] mt-[10px] w-[100%] focus:outline-none border",
                                                    jobPostForm.salary1.touched && jobPostForm.salary1.hasError ? "border-red-600" : "border-off-white"
                                                )}
                                                onBlur={e => {
                                                    onFocusOut("salary1", e.target.value, dispatchForm, jobPostForm);
                                                }}
                                            >
                                                <option value=""></option>
                                                <option value="2-lach">Rs 1 LPA</option>
                                                <option value="1-lach">Rs 2 LPA</option>
                                                <option value="3-lach">Rs 3 LPA</option>
                                            </select>
                                            {jobPostForm.salary1.touched && jobPostForm.salary1.hasError && (
                                                <div className="error-msg">{jobPostForm.salary1.error}</div>
                                            )}
                                        </div>

                                        <div className='relative block w-full'>
                                            <select
                                                id="job-select"
                                                value={finalSalary}
                                                onChange={e => {
                                                    onInputChange("salary2", setFinalSalary(e.target.value), dispatchForm, jobPostForm);
                                                }}
                                                className={classNames(
                                                    "form-control poppins-familybg-[#F8F8F9] rounded-[40px] h-[50px] px-[15px] py-[10px] mt-[10px] w-[100%] focus:outline-none border",
                                                    jobPostForm.salary2.touched && jobPostForm.salary2.hasError ? "border-red-600" : "border-off-white"
                                                )}
                                                onBlur={e => {
                                                    onFocusOut("salary2", e.target.value, dispatchForm, jobPostForm);
                                                }}
                                            >
                                                <option value=""></option>
                                                <option value="2-lach">Rs 2 LPA</option>
                                                <option value="4-lach">Rs 4 LPA</option>
                                                <option value="7-lach">Rs 7 LPA</option>
                                            </select>
                                            {jobPostForm.salary2.touched && jobPostForm.salary2.hasError && (
                                                <div className="error-msg">{jobPostForm.salary2.error}</div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-[20px]">
                                    <label className="text-[#1E243B] text-[16px] leading-[24px] font-normal block">Job Description</label>
                                    <textarea
                                        className={classNames(
                                            "bg-[#F8F8F9] resize-none rounded-[15px] h-[170px] px-[15px] py-[10px] mt-[10px] w-[100%] focus:outline-none border",
                                            jobPostForm.description.touched && jobPostForm.description.hasError ? "border-red-600" : "border-off-white"
                                        )}
                                        type="text"
                                        placeholder="Max 30 words"
                                        value={jobPostForm.description.value}
                                        onChange={e => {
                                            onInputChange("description", e.target.value, dispatchForm, jobPostForm);
                                        }}
                                        onBlur={e => {
                                            onFocusOut("description", e.target.value, dispatchForm, jobPostForm);
                                        }}
                                    />
                                    {jobPostForm.description.touched && jobPostForm.description.hasError && (
                                        <div className="error-msg">{jobPostForm.description.error}</div>
                                    )}
                                </div>
                                <div className="row mb-[20px]">
                                    <label className="text-[#1E243B] text-[16px] leading-[24px] font-normal block">Add skills</label>
                                    {skillList.length > 0 &&
                                        <div className="my-[10px] flex flex-wrap gap-[10px]">
                                            {skillList.map((skill, key) => (
                                                <div key={key} className="bg-[#F5FBD6] h-[40px] text-[#1E243B] leading-[18px] font-normal justify-between px-[30px] py-[8px] flex justify-center items-center rounded-full w-[fit-content]">
                                                    {skill}
                                                    <button className="ml-[10px]" onClick={() => removeSkillHandler(key)}>
                                                        <img src="images/cross.png" alt="" />
                                                    </button>
                                                </div>
                                            ))}
                                        </div>
                                    }
                                    <input
                                        className={classNames(
                                            "bg-[#F8F8F9] rounded-[40px] h-[50px] px-[15px] py-[10px] mt-[10px] w-[100%] focus:outline-none border",
                                            jobPostForm.skill.touched && jobPostForm.skill.hasError ? "error" : ""
                                        )}
                                        type="text"
                                        placeholder="Add skills"
                                        value={skill}
                                        onChange={e => { setSkill(e.target.value)}}
                                        onKeyDown={handleKeyPress}
                                    />
                                    {validateCourseList && (
                                        <div className="error-msg">{jobPostForm.skill.error}</div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditJob;
