import React, { useReducer } from "react";
import {
  onFocusOut,
  onInputChange,
  UPDATE_FORM,
  validateInput,
} from "../component/lib/form/dashLogin";

import classNames from 'classnames';

const initialState = {
  number: { value: "", touched: false, hasError: true, error: "" },
  isFormValid: false,
};

const formReducer = (state, action) => {
  switch (action.type) {
    case UPDATE_FORM:
      const { name, value, hasError, error, touched, isFormValid } =
        action.data;
      return {
        ...state,
        [name]: { ...state[name], value, hasError, error, touched },
        isFormValid,
      };
    default:
      return state;
  }
};

const DeshboardLogin = () => {
  const [formState, dispatch] = useReducer(formReducer, initialState);

  const confirmValidity = (formState, dispatch) => {
    let isFormValid = true;
    for (let name in formState) {
      const item = formState[name];
      const { value } = item;
      const { hasError, error } = validateInput(name, value, formState);
      if (hasError) {
        isFormValid = false;
      }
      if (name) {
        dispatch({
          type: UPDATE_FORM,
          data: {
            name,
            value,
            hasError,
            error,
            touched: true,
            isFormValid,
          },
        });
      }
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    confirmValidity(formState, dispatch, validateInput);
    console.log("output>>>>>", formState.number);
  };

  return (
    <div className="flex justify-center items-center px-[10px] mt-[20px] md:mt-[0] md:h-[100vh]">
      <div className="w-[100%] max-w-[416px] p-[8px]">
        <div className=" w-[100px] md:w-[128px] m-[auto]">
          <img src="images/mobile-app-one.png" alt="#" />
        </div>
        <h2 className="text-[#1E243B] mt-[10px] text-[24px] font-semibold text-center sm:text-[32px]">
          Login with <span className="text-[#A8C523]">Mobile Number</span>
        </h2>
        <p className="text-[#6F7780] text-center text-[16px] mt-[12px] font-normal sm:text-[20px]">
          Enter your registered mobile number
          <br />
          we will send you OTP to verify
        </p>
        <div className="mt-[40px] sm:mt-[62px]">
          <input
            placeholder="Phone Number"
            type="text"
            className={classNames(
              "w-[100%] px-[8px] py-[8px] rounded-[10px] border-[#6F7780] border border-solid",
              formState.number.touched && formState.number.hasError
                ? "border-red-600"
                : "border-off-white"
            )}
            value={formState.number.value}
            maxLength={10}
            onChange={(e) => {
              if (!isNaN(+e.target.value)) {
                onInputChange("number", e.target.value, dispatch, formState);
              }
            }}
            onBlur={(e) => {
              onFocusOut("number", e.target.value, dispatch, formState);
            }}
          />
          {formState.number.touched && formState.number.hasError && (
            <div className="info-text mt-3 ml-2 text-red-600">
              {formState.number.error}
            </div>
          )}
          <br />
          <button
            onClick={onSubmit}
            className="border-[#1E243B] border mt-[25px] sm:mt-[34px] border-solid rounded-[10px] w-[100%] text-[18px] bg-[#1E243B] py-[8px] font-semibold text-[#fff]"
          >
            Continue
          </button>
        </div>
        <p className="text-[#6F7780] sm:mt-[30px] mt-[20px] text-[18px] font-semibold text-center">
          Don't have an account ?{" "}
          <span className="text-[#A8C523]">Sign up</span>
        </p>
      </div>
    </div>
  );
};

export default DeshboardLogin;
