// useUserAuth.js
import { useState, useEffect } from 'react';

const useUserAuth = () => {
  const [user, setUser] = useState(null);
  const [isLogin, setIsLogin]  = useState(null);
  useEffect(() => {
    // Load user data from local storage on component mount
    const storedUser = JSON.parse(localStorage.getItem('userData'));
    const isLoginUser = JSON.parse(localStorage.getItem('isLogin'));
    if (storedUser) {
      setUser(storedUser);
    }
    if(!!isLoginUser){
      setIsLogin(true)
    }else{
      setIsLogin(false)
    }
  }, []);

  const setUserData = (userData) => {
    // Set user data in local storage and update state
    localStorage.setItem('userData', JSON.stringify(userData));
    localStorage.setItem("isLogin", true)
    setUser(userData);
    setIsLogin(true);
  };

  const updateUserData = (updatedData) => {
    // Update user data and store in local storage
    if (user) {
      const updatedUser = { ...user, ...updatedData };
      setUserData(updatedUser);
    }
  };

  const clearUserData = () => {
    // Clear user data from local storage and reset state
    localStorage.removeItem('userData');
    localStorage.removeItem('isLogin');
    setUser(null);
    setIsLogin(null);
  };

  return { user, isLogin, setUserData, updateUserData, clearUserData };
};

export default useUserAuth;