import React, { useEffect, useReducer, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  onFocusOut,
  onInputChange,
  UPDATE_FORM,
  validateInput,
} from "../component/lib/form/availabiltyForm";

import classNames from "classnames";

const initialState = {
  role: { value: "", touched: false, hasError: true, error: "" },
  jobtype: { value: "", touched: false, hasError: true, error: "" },
  salary: { value: "", touched: false, hasError: true, error: "" },
  city: { value: "", touched: false, hasError: true, error: "" },
  // industry: { value: "", touched: false, hasError: true, error: "" },
  isFormValid: false,
};

const formReducer = (state, action) => {
  switch (action.type) {
    case UPDATE_FORM:
      const { name, value, hasError, error, touched, isFormValid } =
        action.data;
      return {
        ...state,
        [name]: { ...state[name], value, hasError, error, touched },
        isFormValid,
      };
    default:
      return state;
  }
};

const Availabilty = () => {
  const [formState, dispatch] = useReducer(formReducer, initialState);
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedJobType, setSelectedJobType] = useState("");
  const [selectedSalary, setSelectedSalary] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedIndustry, setSelectedIndustry] = useState("");
  const [currentSection, setCurrentSection] = useState(1);
  const [dataSubmittedFrist, setDataSubmittedFrist] = useState(false);
  const [dataSubmitted, setDataSubmitted] = useState(false);
  const [dataSubmittedSec, setDataSubmittedSec] = useState(false);
  const [jobsData, setJobsData] = useState([]);
  const navigate = useNavigate();

  // highest education>>>>>>>>>>>>>>>>

  const [formData, setFormData] = useState({
    instituteName: "",
    highestEducation: "",
    batch: "",
    city: "",
  });

  const [validationErrors, setValidationErrors] = useState({
    instituteName: "",
    highestEducation: "",
    batch: "",
    city: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Clear the validation error when the user starts typing
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleSaveAndNext = () => {
    console.log("Education::output>>>>", formData);

    // Basic validation
    const newErrors = {};
    if (!formData.instituteName) {
      newErrors.instituteName = "Institute Name is required";
    }
    if (!formData.highestEducation) {
      newErrors.highestEducation = "Highest Education is required";
    }
    if (!formData.batch) {
      newErrors.batch = "Batch is required";
    }
    if (!formData.city) {
      newErrors.city = "City is required";
    }
    if (Object.keys(newErrors).length === 0) {
      setCurrentSection(currentSection + 1);
      setDataSubmitted(true);
      // Proceed to the next step or perform other actions
      console.log("Form data is valid:", formData);
      localStorage.setItem('formData>>>.', JSON.stringify(formData));
      educationData()
    } else {
      // Display validation errors
      setValidationErrors(newErrors);
    }
  };

  const [formDataBio, setFormDataBio] = useState({
    // Add other fields as needed
    firstName: "",
    lastName: "",
    email: "",
    dob: "",
    address: "",
    gender: "",
    experience: "",
    bio: "",
  });

  const [validationErrorsBio, setValidationErrorsBio] = useState({
    firstName: "",
    lastName: "",
    email: "",
    dob: "",
    address: "",
    gender: "",
    experience: "",
    bio: "",
  });

  const handleInputChangeBio = (e) => {
    const { name, value } = e.target;
    setFormDataBio((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    setValidationErrorsBio((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleSaveAndNextBio = () => {
    console.log("Bio::output>>>>", formDataBio);
    const errors = {};
    myBioData()
    // Basic validation for first name
    if (!formDataBio.firstName) {
      errors.firstName = "First name is required";
    }

    // Basic validation for last name
    if (!formDataBio.lastName) {
      errors.lastName = "Last name is required";
    }

    // Basic email validation
    if (!formDataBio.email || !/^\S+@\S+\.\S+$/.test(formDataBio.email)) {
      errors.email = "Invalid email address";
    }

    // Basic validation for date of birth
    if (!formDataBio.dob) {
      errors.dob = "Date of birth is required";
    }

    // Basic validation for address
    if (!formDataBio.address) {
      errors.address = "Address is required";
    }
    if (!formDataBio.bio || formDataBio.bio.trim() === "") {
      errors.bio = "Bio is required";
    }
    // Basic validation for gender
    if (!formDataBio.gender) {
      errors.gender = "Gender is required";
    }

    // Basic validation for experience
    if (!formDataBio.experience) {
      errors.experience = "Experience is required";
    }

    // Check if there are no validation errors
    if (Object.keys(errors).length === 0) {
      // Log the form data for successful validation
      console.log("Form data:", formDataBio);
      setDataSubmittedSec(true);

      localStorage.setItem('formDataBio', JSON.stringify(formDataBio));

      // Perform any additional actions on successful validation
      console.log("output>>>>>", formDataBio);

      // Redirect to the "/profileSeeker" page
      // navigate("/profileSeeker");
      setTimeout(() => {
        navigate("/profileSeeker");
      }, 2000);
    } else {
      // Display validation errors
      setValidationErrorsBio(errors);
    }
  };


  // end>>>>>>>>>

  const confirmValidity = (formState, dispatch) => {
    let isFormValid = true;
    for (let name in formState) {
      const item = formState[name];
      const { value } = item;
      const { hasError, error } = validateInput(name, value, formState);
      if (hasError) {
        isFormValid = false;
      }
      if (name) {
        dispatch({
          type: UPDATE_FORM,
          data: {
            name,
            value,
            hasError,
            error,
            touched: true,
            isFormValid,
          },
        });
      }
    }
  };
 
  const myBioData = () => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      "https://api-letshireme.onrender.com/api/v1/jobs?page=2",
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json(); 
      })
      .then((result) => {
        console.log("Data received:", result);
        setJobsData(result.data); 
      })
      .catch((error) => console.error("Error fetching data:", error));
  };

  useEffect(() => {
    myBioData()
  }, []);



  const educationData = () => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      "https://api-letshireme.onrender.com/api/v1/jobs?page=2",
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json(); 
      })
      .then((result) => {
        console.log("Data received:", result);
        setJobsData(result.data); 
      })
      .catch((error) => console.error("Error fetching data:", error));
  };

  useEffect(() => {
    educationData()
  }, []);



  const availabiltyData = () => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      "https://api-letshireme.onrender.com/api/v1/jobs?page=2",
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json(); 
      })
      .then((result) => {
        console.log("Data received:", result);
        setJobsData(result.data); 
      })
      .catch((error) => console.error("Error fetching data:", error));
  };

  useEffect(() => {
    availabiltyData()
  }, []);

  

  const onSubmit = (e) => {
    e.preventDefault();
    confirmValidity(formState, dispatch, validateInput);
    console.log("Availability::output>>>>>", formState);
    if (formState.isFormValid) {
      localStorage.setItem("formState>>>>", JSON.stringify(formState));
      setCurrentSection(currentSection + 1);
      setDataSubmittedFrist(true);
      availabiltyData();
    }
  };

  return (
    <div className="flex justify-center items-center pt-[50px] pb-[50px] w-[100%]">
      <div className="tabs_wrapper">
        <div className=" ">
          <div className="tab w-[1024px] px-[50px]">
            <button
              className="text-[14px] font-[600] text-[#1E243B]"
              // onClick={() => setCurrentSection(1)}
              onClick={() => {
                if (!dataSubmittedFrist) {
                  return;
                }
                setCurrentSection(1);
              }}
            >
              <span className="w-[40px] h-[40px] m-[auto] inline-block flex justify-center items-center">
                {!dataSubmittedFrist ? (
                  <img
                    className={`m-[auto] ${
                      currentSection === 1 ? "active-img" : ""
                    }`}
                    src={
                      currentSection === 1
                        ? "images/job_11.png"
                        : "images/jobCopy_11.png"
                    }
                    alt="img"
                  />
                ) : (
                  <img
                    className={`m-[auto] ${
                      currentSection === 1 ? "active-img" : ""
                    }`}
                    src="images/check-availabilty.png"
                    alt="img"
                  />
                )}
              </span>
              <p className="tab_span">
                <span>Availability</span>
                <hr />
              </p>
            </button>
            <button
              className="text-[14px] font-[600] text-[#1E243B]"
              // onClick={() => setCurrentSection(2)}
              onClick={() => {
                if (!dataSubmitted) {
                  return;
                }

                setCurrentSection(2);
              }}
            >
              <span className="w-[40px] h-[40px] m-[auto] inline-block flex justify-center items-center">
                {" "}
                {!dataSubmitted ? (
                  <img
                    className={`m-[auto] ${
                      currentSection === 2 ? "active-img" : ""
                    }`}
                    src={
                      currentSection === 2
                        ? "images/Ed_11.png"
                        : "images/school.png"
                    }
                    alt="img"
                  />
                ) : (
                  <img
                    className={`m-[auto] ${
                      currentSection === 2 ? "active-img" : ""
                    }`}
                    src="images/check-availabilty.png"
                    alt="img"
                  />
                )}
              </span>
              <p className="tab_span">
                <span>Education</span>
                <hr />
              </p>
            </button>
            <button
              className="text-[14px] font-[600] text-[#1E243B]"
              // onClick={() => setCurrentSection(3)}
              onClick={() => {
                if (!dataSubmittedSec) {
                  return;
                }

                setCurrentSection(3);
              }}
            >
              <span className="w-[40px] h-[40px] m-[auto] inline-block flex justify-center items-center">
                {!dataSubmittedSec ? (
                  <img
                    className={`m-[auto] ${
                      currentSection === 3 ? "active-img" : ""
                    }`}
                    src={
                      currentSection === 3
                        ? "images/myB_11.png"
                        : "images/bio.png"
                    }
                    alt="img"
                  />
                ) : (
                  <img
                    className={`m-[auto] ${
                      currentSection === 3 ? "active-img" : ""
                    }`}
                    src="images/check-availabilty.png"
                    alt="img"
                  />
                )}
              </span>
              <p className="tab_span">
                <span>My Bio</span>
              </p>
            </button>
          </div>
          <div className="overflow-y-auto h-[calc(100vh_-_264px)] overflow-auto overflow-scroll px-[50px] w-[1024px]">
            {currentSection === 1 && (
              <div className="tabcontent">
                <div className="">
                  <div className="mt-[60px]">
                    <p className="text-[18px] font-[600] tracking-[-0.078px] text-[#1E243B]">
                      Availabilty
                    </p>
                    <span className="text-[14px] text-[#828282] font-[500] tracking-[-0.078px]">
                      What type of job are you looking for?
                    </span>
                  </div>
                  <div className="flex gap-6 mt-[25px]">
                    <div className="w-[70%]">
                      <select
                        value={selectedRole}
                        onChange={(e) => {
                          onInputChange(
                            "role",
                            setSelectedRole(e.target.value),
                            dispatch,
                            formState
                          );
                        }}
                        className={classNames(
                          "text-[#1E243B] text-[14px] font-[600] w-[100%] h-[50px] mt-2 px-[15px] py-[10px]  border-[#6F7780] border border-solid mb-[20px] appearance-none",
                          formState.role &&
                            formState.role.touched &&
                            formState.role.hasError
                            ? "border-red-600"
                            : "border-off-white"
                        )}
                        onBlur={(e) => {
                          onFocusOut(
                            "role",
                            e.target.value,
                            dispatch,
                            formState
                          );
                        }}
                        id="myDropdown"
                        name="myDropdown"
                      >
                        <option value="" disabled selected>
                          Job Role
                        </option>
                        {/* {jobsData.map((job) => (
                          <option key={job._id} value={job.jobTitle}>
                            {job.jobTitle}
                          </option>
                        ))} */}

                        {[...new Set(jobsData.map(job => job.jobTitle))].map((jobTitle, index) => (
                            <option key={index} value={jobTitle}>
                                {jobTitle}
                            </option>
                        ))}

                        {/* <option value="option1">Option 1</option>
                        <option value="option2">Option 2</option>
                        <option value="option3">Option 3</option> */}
                      </select>
                      {formState.role &&
                        formState.role.touched &&
                        formState.role.hasError && (
                          <div style={{ padding: "0px", color: "red" }}>
                            {formState.role.error}
                          </div>
                        )}
                      <select
                        value={selectedJobType}
                        onChange={(e) => {
                          onInputChange(
                            "jobtype",
                            setSelectedJobType(e.target.value),
                            dispatch,
                            formState
                          );
                        }}
                        className={classNames(
                          "text-[#1E243B] text-[14px] font-[600] w-[100%] h-[50px] mt-2 px-[15px] py-[10px]  border-[#6F7780] border border-solid mb-[20px] appearance-none",
                          formState.jobtype &&
                            formState.jobtype.touched &&
                            formState.jobtype.hasError
                            ? "border-red-600"
                            : "border-off-white"
                        )}
                        onBlur={(e) => {
                          onFocusOut(
                            "jobtype",
                            e.target.value,
                            dispatch,
                            formState
                          );
                        }}
                        id="myDropdown"
                        name="myDropdown"
                      >
                        <option value="" disabled selected>
                          Job Type
                        </option>
                        {/* <option value="option1">Option 1</option>
                        <option value="option2">Option 2</option>
                        <option value="option3">Option 3</option> */}
                        {/* {jobsData.map((jobType) => (
                          <option key={jobType._id} value={jobType.jobDetails.jobType}>
                            {jobType.jobDetails.jobType}
                          </option>
                        ))} */}

                        {[...new Set(jobsData.map(job => job.jobDetails.jobType))].map((jobType, index) => (
                            <option key={index} value={jobType}>
                                {jobType}
                            </option>
                        ))}
                      </select>
                      {formState.jobtype &&
                        formState.jobtype.touched &&
                        formState.jobtype.hasError && (
                          <div style={{ padding: "0px", color: "red" }}>
                            {formState.jobtype.error}
                          </div>
                        )}
                      <select
                        value={selectedSalary}
                        onChange={(e) => {
                          onInputChange(
                            "salary",
                            setSelectedSalary(e.target.value),
                            dispatch,
                            formState
                          );
                        }}
                        className={classNames(
                          "text-[#1E243B] text-[14px] font-[600] w-[100%] h-[50px] mt-2 px-[15px] py-[10px]  border-[#6F7780] border border-solid mb-[20px] appearance-none",
                          formState.salary &&
                            formState.salary.touched &&
                            formState.salary.hasError
                            ? "border-red-600"
                            : "border-off-white"
                        )}
                        onBlur={(e) => {
                          onFocusOut(
                            "salary",
                            e.target.value,
                            dispatch,
                            formState
                          );
                        }}
                        id="myDropdown"
                        name="myDropdown"
                      >
                        <option value="" disabled selected>
                          Expected Salary
                        </option>
                        {/* <option value="option1">Option 1</option>
                        <option value="option2">Option 2</option>
                        <option value="option3">Option 3</option> */}
                        {/* {jobsData.map((salary) => (
                          <option key={salary._id} value={salary.jobDetails.salary}>
                            {salary.jobDetails.salary}
                          </option>
                        ))} */}

                        {[...new Set(jobsData.map(job => job.jobDetails.salary))].map((salary, index) => (
                            <option key={index} value={salary}>
                                {salary}
                            </option>
                        ))}

                      </select>
                      {formState.salary &&
                        formState.salary.touched &&
                        formState.salary.hasError && (
                          <div style={{ padding: "0px", color: "red" }}>
                            {formState.salary.error}
                          </div>
                        )}
                      <select
                        value={selectedCity}
                        onChange={(e) => {
                          onInputChange(
                            "city",
                            setSelectedCity(e.target.value),
                            dispatch,
                            formState
                          );
                        }}
                        className={classNames(
                          "text-[#1E243B] text-[14px] font-[600] w-[100%] h-[50px] mt-2 px-[15px] py-[10px]  border-[#6F7780] border border-solid mb-[20px] appearance-none",
                          formState.city &&
                            formState.city.touched &&
                            formState.city.hasError
                            ? "border-red-600"
                            : "border-off-white"
                        )}
                        onBlur={(e) => {
                          onFocusOut(
                            "city",
                            e.target.value,
                            dispatch,
                            formState
                          );
                        }}
                        id="myDropdown"
                        name="myDropdown"
                      >
                        <option value="" disabled selected>
                          City
                        </option>
                        {/* <option value="option1">Option 1</option>
                        <option value="option2">Option 2</option>
                        <option value="option3">Option 3</option> */}
                        {/* {jobsData.map((City) => (
                          <option key={City._id} value={City.address}>
                            {City.address}
                          </option>
                        ))} */}

                        {[...new Set(jobsData.map(job => job.address))].map((City, index) => (
                            <option key={index} value={City}>
                                {City}
                            </option>
                        ))}
                      </select>
                      {formState.city &&
                        formState.city.touched &&
                        formState.city.hasError && (
                          <div style={{ padding: "0px", color: "red" }}>
                            {formState.city.error}
                          </div>
                        )}
                      {/* <select
                        value={selectedIndustry}
                        onChange={(e) => {
                          onInputChange(
                            "industry",
                            setSelectedIndustry(e.target.value),
                            dispatch,
                            formState
                          );
                        }}
                        className={classNames(
                          "text-[#1E243B] text-[14px] font-[600] w-[100%] h-[50px] mt-2 px-[15px] py-[10px]  border-[#6F7780] border border-solid mb-[20px] appearance-none",
                          formState.industry &&
                            formState.industry.touched &&
                            formState.industry.hasError
                            ? "border-red-600"
                            : "border-off-white"
                        )}
                        onBlur={(e) => {
                          onFocusOut(
                            "industry",
                            e.target.value,
                            dispatch,
                            formState
                          );
                        }}
                        id="myDropdown"
                        name="myDropdown"
                      >
                        <option value="" disabled selected>
                          Industry
                        </option>
                        <option value="option1">Option 1</option>
                        <option value="option2">Option 2</option>
                        <option value="option3">Option 3</option>
                      </select>
                      {formState.industry &&
                        formState.industry.touched &&
                        formState.industry.hasError && (
                          <div style={{ padding: "0px", color: "red" }}>
                            {formState.industry.error}
                          </div>
                        )} */}
                      <button
                        onClick={onSubmit}
                        className="w-[169px] bg-[#1E243B] mt-[15px] py-[15px] border-[#1E243B] border border-solid text-[#fff] text-[14px] font-bold tracking-[-0.078px]"
                      >
                        SAVE & NEXT
                      </button>
                    </div>
                    <div className="w-[30%] pl-[20px]">
                      <div className="flex justify-between">
                        <p className="text-[#828282] text-[14px] font-normal">
                          Choose atleast 5 industry
                        </p>
                        <p className="text-[#1E243B] text-[14px] font-semibold">
                          <span className="text-[#A8C523]">3</span>/5
                        </p>
                      </div>
                      <div class="bg-[#F6F5F8] rounded-[50px] h-[14px] mt-[20px] mb-[30px]">
                        <div class="bg-[#1E243B] rounded-[50px] w-[50%] h-[14px]"></div>
                      </div>
                      <div className="relative">
                        <input
                          className="text-[#1E243B] text-[14px] font-[600] w-[100%] h-[50px] mt-2 pr-[15px] pl-[46px] py-[10px] border-[#6F7780] border border-solid mb-[20px] placeholder:text-[#1E243B]"
                          type="text"
                          placeholder="Search"
                        />
                        <span>
                          <img
                            className="m-[auto] w-[15px] h-[15px] absolute top-[25px] left-[20px]"
                            src="images/search_chat.png"
                            alt="img"
                          />
                        </span>
                      </div>
                      <div className="flex gap-2 mt-[10px] justify-center">
                        <p className="w-[170px] h-[50px] flex justify-center items-center bg-[#F6F5F8] text-center text-[14px] font-semibold color-[#1E243B]">
                          JavaScript
                        </p>
                        <p className="w-[170px] h-[50px] flex justify-center items-center bg-[#F6F5F8] text-center text-[14px] font-semibold color-[#1E243B]">
                          React Native
                        </p>
                      </div>
                      <div className="flex gap-2 mt-[10px] justify-center">
                        <p className="w-[170px] h-[50px] flex justify-center items-center bg-[#F6F5F8] text-center text-[14px] font-semibold color-[#1E243B]">
                          Kotlin
                        </p>
                        <p className="w-[170px] h-[50px] flex justify-center items-center bg-[#F5FBD6] text-center text-[14px] font-semibold color-[#1E243B]">
                          Flutter
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {currentSection === 2 && (
              <div className="tabcontent">
                <div className="">
                  <div className="mt-[60px]">
                    <p className="text-[18px] font-[600] tracking-[-0.078px] text-[#1E243B]">
                      Highest Education
                    </p>
                    <span className="text-[14px] text-[#828282] font-[500] tracking-[-0.078px]">
                      Please fill in your highest education details
                    </span>
                  </div>
                  <div className="flex gap-6 mt-[25px]">
                    <div className="w-[70%]">
                      <select
                        id="instituteNameDropdown"
                        name="instituteName"
                        value={formData.instituteName}
                        onChange={handleInputChange}
                        className={`text-[#1E243B] text-[14px] font-[600] w-[100%] h-[50px] mt-2 px-[15px] py-[10px] border ${
                          validationErrors.instituteName
                            ? "border-red-500"
                            : "border-[#6F7780]"
                        } border-solid mb-[20px] appearance-none`}
                      >
                        <option value="" disabled selected>
                          Institute Name
                        </option>
                        {/* <option value="option1">Option 1</option>
                        <option value="option2">Option 2</option>
                        <option value="option3">Option 3</option> */}
                        {/* {jobsData.map((institute) => (
                          <option key={institute._id} value={institute.companyName}>
                            {institute.companyName}
                          </option>
                        ))} */}

                        {[...new Set(jobsData.map(job => job.companyName))].map((companyName, index) => (
                            <option key={index} value={companyName}>
                                {companyName}
                            </option>
                        ))}

                      </select>
                      {validationErrors.instituteName && (
                        <p className="text-red-500">
                          {validationErrors.instituteName}
                        </p>
                      )}
                      <select
                        id="myDropdown"
                        name="highestEducation"
                        value={formData.highestEducation}
                        onChange={handleInputChange}
                        className={`text-[#1E243B] text-[14px] font-[600] w-[100%] h-[50px] mt-2 px-[15px] py-[10px] border ${
                          validationErrors.highestEducation
                            ? "border-red-500"
                            : "border-[#6F7780]"
                        } border-solid mb-[20px] appearance-none`}
                      >
                        <option value="" disabled selected>
                          Higest Education
                        </option>
                        {/* <option value="option1">Option 1</option>
                        <option value="option2">Option 2</option>
                        <option value="option3">Option 3</option> */}
                        {/* {jobsData.map((highest) => (
                          <option key={highest._id} value={highest.qualifications.highestEducation}>
                            {highest.qualifications.highestEducation}
                          </option>
                        ))} */}

                        {[...new Set(jobsData.map(job => job.qualifications.highestEducation))].map((highestEducation, index) => (
                            <option key={index} value={highestEducation}>
                                {highestEducation}
                            </option>
                        ))}
                      </select>
                      {validationErrors.highestEducation && (
                        <p className="text-red-500">
                          {validationErrors.highestEducation}
                        </p>
                      )}
                      <select
                        id="myDropdown"
                        name="batch"
                        value={formData.batch}
                        onChange={handleInputChange}
                        className={`text-[#1E243B] text-[14px] font-[600] w-[100%] h-[50px] mt-2 px-[15px] py-[10px] border ${
                          validationErrors.batch
                            ? "border-red-500"
                            : "border-[#6F7780]"
                        } border-solid mb-[20px] appearance-none`}
                      >
                        <option value="" disabled selected>
                          Batch
                        </option>
                        <option value="option1">Option 1</option>
                        <option value="option2">Option 2</option>
                        <option value="option3">Option 3</option>
                      </select>
                      {validationErrors.batch && (
                        <p className="text-red-500">{validationErrors.batch}</p>
                      )}
                      <select
                        id="myDropdown"
                        name="city"
                        value={formData.city}
                        onChange={handleInputChange}
                        className={`text-[#1E243B] text-[14px] font-[600] w-[100%] h-[50px] mt-2 px-[15px] py-[10px] border ${
                          validationErrors.city
                            ? "border-red-500"
                            : "border-[#6F7780]"
                        } border-solid mb-[20px] appearance-none`}
                      >
                        <option value="" disabled selected>
                          City
                        </option>
                        {/* <option value="option1">Option 1</option>
                        <option value="option2">Option 2</option>
                        <option value="option3">Option 3</option> */}
                        {/* {jobsData.map((edCity) => (
                          <option key={edCity._id} value={edCity.address}>
                            {edCity.address}
                          </option>
                        ))} */}

                        {[...new Set(jobsData.map(job => job.address))].map((edCity, index) => (
                            <option key={index} value={edCity}>
                                {edCity}
                            </option>
                        ))}
                      </select>
                      {validationErrors.city && (
                        <p className="text-red-500">{validationErrors.city}</p>
                      )}
                      <button
                        onClick={handleSaveAndNext}
                        className="w-[169px] bg-[#1E243B] mt-[15px] py-[15px] border-[#1E243B] border border-solid text-[#fff] text-[14px] font-bold tracking-[-0.078px] appearance-none"
                      >
                        SAVE & NEXT
                      </button>
                    </div>
                    <div className="w-[30%] pl-[20px] mt-[-31px] mt-[-64px]">
                      <div className="flex justify-between">
                        <p className="text-[#1E243B] text-[18px] font-[700] leading-[0.078px]">
                          Institute Name
                        </p>
                      </div>
                      <div className="relative mt-[30px]">
                        <input
                          className="text-[#1E243B] text-[14px] font-[600] w-[100%] h-[50px] mt-2 pr-[15px] pl-[46px] py-[10px] border-[#6F7780] border border-solid mb-[20px] placeholder:text-[#1E243B]"
                          type="text"
                          placeholder="Search"
                        />
                        <span>
                          <img
                            className="m-[auto] w-[15px] h-[15px] absolute top-[25px] left-[20px]"
                            src="images/search_chat.png"
                            alt="img"
                          />
                        </span>
                      </div>
                      <div className="last:border-[none] ">
                        <p className="text-[16px] font-[500] text-[#1E243B] with_border pb-[15px] mb-[15px]">
                          Indian Institute of Technology Bombay (IITB)
                        </p>
                        <p className="text-[16px] font-[500] text-[#1E243B] with_border pb-[15px] mb-[15px]">
                          Indian Institute of Technology Madras (IITM)
                        </p>
                        <p className="text-[16px] font-[500] text-[#1E243B] with_border pb-[15px] mb-[15px]">
                          Indian Institute of Science (IISC) Bangalore
                        </p>
                        <p className="text-[16px] font-[500] text-[#1E243B] with_border pb-[15px] mb-[15px]">
                          Indian Institute of Technology Delhi (IITD)
                        </p>
                        <p className="text-[16px] font-[500] text-[#1E243B] with_border pb-[15px] mb-[15px]">
                          Indian Institute of Technology Madras (IITM)
                        </p>
                        <p className="text-[16px] font-[500] text-[#1E243B] with_border pb-[15px] mb-[15px]">
                          Indian Institute of Technology Kharagpur (IITKGP)
                        </p>
                        <p className="text-[16px] font-[500] text-[#1E243B] with_border pb-[15px] mb-[15px]">
                          Indian Institute of Technology Madras (IITM)
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {currentSection === 3 && (
              <div className="tabcontent">
                <div className="">
                  <div className="mt-[60px]">
                    <p className="text-[18px] font-[600] tracking-[-0.078px] text-[#1E243B]">
                      Personal Information
                    </p>
                    <span className="text-[14px] text-[#828282] font-[500] tracking-[-0.078px]">
                      Please fill your personal information
                    </span>
                  </div>
                  <div className="flex gap-6 mt-[25px]">
                    <div className="w-[50%]">
                      <form className="mb-[20px] mt-[20px]">
                        <div className="relative flex justify-start items-center gap-[15px]">
                          <input
                            type="file"
                            id="img"
                            name="img"
                            accept="image/* "
                            className="w-[62px] h-[62px] opacity-[0]"
                          />
                          <div className="w-[62px] h-[62px] bg-[#6F7780] absolute top-[0px] z-[-1] truncate rounded-[50px] border-[1px] border-soild border-[#6F7780]">
                            <img
                              className=""
                              src="images/office_girl.jpg"
                              alt="img"
                            />
                          </div>
                          <span className="w-[20px] h-[20px] p-[4px] absolute bottom-[5px] left-[48px] bg-[#32bea6] rounded-[50px] inline-block">
                            <img className="" src="images/edit.png" alt="img" />
                          </span>
                        </div>
                      </form>
                      <div className="relative">
                        <select
                          id="genderDropdown"
                          name="gender"
                          value={formDataBio.gender}
                          onChange={handleInputChangeBio}
                          className={`text-[#1E243B] text-[14px] font-[600] w-[100%] h-[50px] mt-2 px-[15px] border-[#6F7780] py-[10px] border ${
                            validationErrorsBio.gender
                              ? "border-red-500"
                              : "border-[#6F7780]"
                          } border-solid mb-[20px] appearance-none`}
                        >
                          <option value="" disabled selected>
                            Select Gender
                          </option>
                          <option value="option1">Female</option>
                          <option value="option2">Male</option>
                          <option value="option3">Other</option>
                        </select>
                        {validationErrorsBio.gender && (
                          <p className="text-red-500">
                            {validationErrorsBio.gender}
                          </p>
                        )}
                        <img
                          className="absolute top-[29px] right-[15px] z-[999999] w-[15px]"
                          src="images/down-arrow.png"
                          alt="img"
                        />
                      </div>
                      <div className="relative">
                        <select
                          id="exDropdown"
                          name="experience"
                          value={formDataBio.experience}
                          onChange={handleInputChangeBio}
                          className={`text-[#1E243B] text-[14px] font-[600] w-[100%] h-[50px] mt-2 px-[15px] border-[#6F7780] py-[10px] border ${
                            validationErrorsBio.experience
                              ? "border-red-500"
                              : "border-[#6F7780]"
                          } border-solid mb-[20px] appearance-none`}
                        >
                          <option value="" disabled selected>
                            Select Experience
                          </option>
                          {/* <option value="option1">Option 1</option>
                          <option value="option2">Option 2</option>
                          <option value="option3">Option 3</option> */}


                          {/* {jobsData.map((experienceSeclect) => (
                          <option key={experienceSeclect._id} value={experienceSeclect.qualifications.experience}>
                            {experienceSeclect.qualifications.experience}
                          </option>
                          ))}  */}


                           {/* Extract unique experience qualifications */}
                          {[...new Set(jobsData.map(job => job.qualifications.experience))].map((experience, index) => (
                              <option key={index} value={experience}>
                                  {experience}
                              </option>
                          ))}
                        </select>
                        {validationErrorsBio.experience && (
                          <p className="text-red-500">
                            {validationErrorsBio.experience}
                          </p>
                        )}
                        <img
                          className="absolute top-[29px] right-[15px] z-[999999] w-[15px]"
                          src="images/down-arrow.png"
                          alt="img"
                        />
                      </div>
                      <button
                        onClick={handleSaveAndNextBio}
                        className="w-[169px] bg-[#1E243B] mt-[15px] py-[15px] border-[#1E243B] border border-solid text-[#fff] text-[14px] font-bold tracking-[-0.078px]"
                      >
                        SAVE & NEXT
                      </button>
                    </div>
                    <div className="w-[50%] pl-[20px]">
                      <div className="last:border-[none] ">
                        <div className="with_border pb-[10px] mt-[20px]">
                          <label className="text-[14px] font-[600] text-[#1E243B]">
                            First Name
                          </label>
                          <br />
                          <input
                            placeholder="Your first name here"
                            name="firstName"
                            value={formDataBio.firstName}
                            onChange={handleInputChangeBio}
                            className={`text-[12px] font-[400] text-[#828282] w-[100%] ${
                              validationErrorsBio.firstName
                                ? "border-red-500"
                                : ""
                            }`}
                          />
                        </div>
                        {validationErrorsBio.firstName && (
                          <p className="text-red-500">
                            {validationErrorsBio.firstName}
                          </p>
                        )}
                        <div className="with_border pb-[10px] mt-[20px]">
                          <label className="text-[14px] font-[600] text-[#1E243B]">
                            Last Name
                          </label>
                          <br />
                          <input
                            name="lastName"
                            value={formDataBio.lastName}
                            onChange={handleInputChangeBio}
                            className={`text-[12px] font-[400] text-[#828282] w-[100%] ${
                              validationErrorsBio.lastName
                                ? "border-red-500"
                                : ""
                            }`}
                            placeholder="Your last name here"
                          />
                        </div>
                        {validationErrorsBio.lastName && (
                          <p className="text-red-500">
                            {validationErrorsBio.lastName}
                          </p>
                        )}
                        <div className="with_border pb-[10px] mt-[20px]">
                          <label className="text-[14px] font-[600] text-[#1E243B]">
                            My Email
                          </label>
                          <br />
                          <input
                            placeholder="Your email here"
                            name="email"
                            value={formDataBio.email}
                            onChange={handleInputChangeBio}
                            className={`text-[12px] font-[400] text-[#828282] w-[100%] ${
                              validationErrorsBio.email ? "border-red-500" : ""
                            }`}
                          />
                        </div>
                        {validationErrorsBio.email && (
                          <p className="text-red-500">
                            {validationErrorsBio.email}
                          </p>
                        )}
                        <div className="with_border pb-[10px] mt-[20px]">
                          <label className="text-[14px] font-[600] text-[#1E243B]">
                            Date of Birth
                          </label>
                          <br />
                          <input
                            placeholder="Your date of birth here"
                            name="dob"
                            value={formDataBio.dob}
                            onChange={handleInputChangeBio}
                            className={`text-[12px] font-[400] text-[#828282] w-[100%] ${
                              validationErrorsBio.dob ? "border-red-500" : ""
                            }`}
                          />
                        </div>
                        {validationErrorsBio.dob && (
                          <p className="text-red-500">
                            {validationErrorsBio.dob}
                          </p>
                        )}
                        <div className="with_border pb-[10px] mt-[20px]">
                          <label className="text-[14px] font-[600] text-[#1E243B]">
                            Address
                          </label>
                          <br />
                          <input
                            placeholder="Rammurthy nagar, Bangalore-560016"
                            name="address"
                            value={formDataBio.address}
                            onChange={handleInputChangeBio}
                            className={`text-[12px] font-[400] text-[#828282] w-[100%] ${
                              validationErrorsBio.address
                                ? "border-red-500"
                                : ""
                            }`}
                          />
                        </div>
                        {validationErrorsBio.address && (
                          <p className="text-red-500">
                            {validationErrorsBio.address}
                          </p>
                        )}
                        <div className="with_border pb-[10px] mt-[20px]">
                          <label className="text-[14px] font-[600] text-[#1E243B]">
                            My Bio
                          </label>
                          <br />
                          <input
                            placeholder="I have an excellent OOP, C# Language and the .NET
                          framework knowledge. Also JavaScript Language..."
                            name="bio"
                            value={formDataBio.bio}
                            onChange={handleInputChangeBio}
                            className={`text-[12px] font-[400] text-[#828282] w-[100%] ${
                              validationErrorsBio.bio ? "border-red-500" : ""
                            }`}
                          />
                        </div>
                        {validationErrorsBio.bio && (
                          <p className="text-red-500">
                            {validationErrorsBio.bio}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Availabilty;
