import React from "react";
function UserList(props) {
  return (
    <div>
      <div
        className="flex  mx-[15px] py-[8px] justify-between border-b-[1px]  
                                border-b-[solid] border-b-[rgba(30, 36 , 59 , 0.03)] items-center"
      >
        <div className="flex items-center">
          <div className="relative w-[55px] h-[55px] mr-[10px]">
            <span className="w-[55px] h-[55px] inline-block">
              <img
                className="w-[100%] rounded-full"
                src={props.ProfileimageUrl}
                alt={props.ProfileimageAlt}
              />
            </span>
          </div>
          <div>
            <span className="block text-[#1e243b] leading-[24px] text-[14px] font-medium">
              {props.name}
            </span>
            <span className="block text-[#1e243b] leading-[24px] text-[14px] font-light whitespace-nowrap overflow-hidden text-ellipsis w-[180px]">
              {props.message}
            </span>
          </div>
        </div>
        <div className="w-[60px] text-right">
          <span className="text-[#1e243b] leading-[24px] text-[12px] font-medium">
            {props.time}
          </span>
          <span className="block text-end">
            <div className="bg-[#1E243B] w-[16px] h-[16px] text-[#fff] rounded-full leading-[16px] text-[8px] font-normal flex justify-center align-center ml-[auto]">
              {props.badgeNotification}
            </div>
            {/* <div>
              <img src="images/tick.png" />
            </div> */}
          </span>
        </div>
      </div>
    </div>
  );
}

export default UserList;
